import {
    NODE_ENV_IS_DEVELOPMENT,
    REACT_APP_CLIENT,
} from './clients-internal/environment.constants';

export const rootElement = document.getElementById('root');

// if running locally, use the env variable as it's easier
// but in production rely on the data-attribute being set
export const client =
    (NODE_ENV_IS_DEVELOPMENT
        ? REACT_APP_CLIENT
        : rootElement?.getAttribute('data-react-app-client')) || 'unknown';
