import { VerificationListRequest } from "src/models/verifications/verification.models";
import { appStringify } from "src/routes/router.helper";

export type VerificationPageBaseParams = {
    verificationId?: string;
};

export const verificationPaths = {
    list: '/verifications',
    details: '/verifications/:verificationId(\\d+)',
};

export const verificationPathCreator = {
    list: (query: VerificationListRequest) => `/verifications${appStringify(query)}`,
    listDefault: () => verificationPathCreator.list({ status: ['Pending'] }),
    details: (id: number) => `/verifications/${id}`
};
