import { useActiveEntity } from 'src/ui/shared/hooks/active-entity.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

export const useActiveVerificationNormalized = () => {
    const { fetch, id, update } = useActiveEntity(state => state.verifications);

    const normalized = useRootSelector(state => state.verifications.normalized[id || -1]);

    return {
        fetch,
        update,
        id,
        normalized,
    };
};
