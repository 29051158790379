import {
    VerifierSource,
    VerifierSourceEntity,
    VerifierSourceStudent,
    Experience,
    ExperienceSkill,
    Reflection,
    Skill,
    Verification,
    VerificationStatus,
    ExperienceResource,
} from 'src/typings/api-models.generated';
import { EntityPageRequest } from '../api/request.model';
import { OptionType } from 'src/logic/features/options/option.models';

export interface ExperienceSkillNormalized {
    skill: Skill;
    experienceSkill: ExperienceSkill;
    reflections: Reflection[];
}

export interface VerificationSourceNormalized {
    source: VerifierSource;
    entity?: VerifierSourceEntity;
    student?: VerifierSourceStudent;
}

export interface VerificationNormalized {
    id: number;
    verification: Verification;
    experience: Experience;
    resources: ExperienceResource[];
    experienceReflections: Reflection[];
    skills: ExperienceSkillNormalized[];
    source: VerificationSourceNormalized;
}

export interface VerificationListRequest extends EntityPageRequest {
    status?: Capitalize<VerificationStatus>[];
    events?: number[] | 'all'
    activities?: number[] | 'all'
}

export const VERIFICATION_STATUS_OPTIONS: Capitalize<VerificationStatus>[] = [
    'Pending',
    'Verified',
    'Denied'
];

export type ExperienceSourceIdPrefix = 'Event|' | 'Activity|';

export type ExperienceSourceIdMeta = {
    [key in OptionType]: ExperienceSourceIdPrefix;
}

export const experienceSourceIdMetaMap: ExperienceSourceIdMeta = {
    'events': 'Event|',
    'activities': 'Activity|'
}