import { getClientConfig } from 'src/clients-internal/configuration.init';
import { apiStringify } from 'src/logic/helpers/api-query-string.helper';
import { fetchHelper } from 'src/logic/helpers/fetch.helper';
import { EntityRequest } from 'src/models/api/request.model';
import { ApiIncludeResponse, ApiResponse, ApiSearchIncludeResponse } from 'src/models/api/response.model';
import { ObsApiExpected } from 'src/models/api/service.model';
import { experienceSourceIdMetaMap, VerificationListRequest } from 'src/models/verifications/verification.models';
import { EmployerVerificationInclude, Verification, VerificationDenyModel } from 'src/typings/api-models.generated';

const baseUrl = () => getClientConfig().careerHubBasePath;
const { events: eventPrefix, activities: activityPrefix } = experienceSourceIdMetaMap;

const buildSourceIds = (request: VerificationListRequest) => {
    if (!request.activities && !request.events) {
        return undefined;
    }

    const sources: string[] = [];
    if(request.activities === 'all') {
        sources.push(activityPrefix);
    } else if (request.activities) {
        request.activities.forEach(x => {
            sources.push(activityPrefix + x);
        });
    }

    if (request.events === 'all') {
        sources.push(eventPrefix);
    } else if(request.events) {
        request.events.forEach(x => {
            sources.push(eventPrefix + x);
        })
    }

    return sources;
}

export const list = (
    request: VerificationListRequest
): ObsApiExpected<
    ApiSearchIncludeResponse<Verification, VerificationListRequest, EmployerVerificationInclude>
> => {

    const query = {
        skip: request.skip,
        take: request.take,
        status: request.status,
        sourceIds: buildSourceIds(request)
    };

    return fetchHelper.get(
        `${baseUrl()}/services/employers/v1/verifications${apiStringify(query)}`
    );
};

export const single = (request: EntityRequest): ObsApiExpected<ApiIncludeResponse<Verification, EmployerVerificationInclude>> => {
    return fetchHelper.get(
        `${baseUrl()}/services/employers/v1/verifications/${request.id}`
    );
}

export const verify = (request: EntityRequest): ObsApiExpected<ApiResponse<Verification>> => {
    return fetchHelper.post(
        `${baseUrl()}/services/employers/v1/verifications/${request.id}/verify`,
        undefined
    );
}

export const deny = (id: number, model: VerificationDenyModel): ObsApiExpected<ApiResponse<Verification>> => {
    return fetchHelper.post(
        `${baseUrl()}/services/employers/v1/verifications/${id}/deny`,
        model
    );
}