import { Row, Col } from 'react-bootstrap';

interface Props {
    header?: React.ReactNode;
    properties: React.ReactNode;
    children: React.ReactNode;
}

export const Details = ({
    header,
    children,
    properties,
}: Props): React.ReactElement => {
    return (
        <>
            {header && <div className="px-3 py-2 mb-3 bg-details-alt">{header}</div>}

            <Row>
                <Col xs={12} sm={8} className="text-justify pr-sm-4">
                    {children}
                </Col>
                <Col xs={12} sm={4}>
                    {properties}
                </Col>
            </Row>
        </>
    );
};
