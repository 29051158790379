import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import services, { Services } from './features/service.root-index';
import { sentryEnhancer } from './setup/sentry.enhancer';
import rootEpic from './features/epic.root-index';
import rootReducer, { routerHistory } from './features/reducer.root-index';
import type { RootState } from 'src/logic/features/root-state.model';
import { RootAction } from './features/action.root-index';

// const loggerMiddleware: Middleware = ({ getState }) => {
//     return next => action => {
//         console.log('will dispatch', action);

//         // Call the next dispatch method in the middleware chain.
//         const returnValue = next(action);

//         // console.log('state after dispatch', getState());

//         // This will likely be the action itself, unless
//         // a middleware further in chain changed it.
//         return returnValue;
//     };
// };

const epicMiddleWare = createEpicMiddleware<RootAction, RootAction, RootState, Services>({
    dependencies: services,
});

const middlewares = [routerMiddleware(routerHistory), epicMiddleWare];

const enhancer = composeWithDevTools(applyMiddleware(...middlewares), sentryEnhancer);

const initialState = {};

const configureStore = () => {
    const store = createStore(rootReducer, initialState, enhancer);
    epicMiddleWare.run(rootEpic);
    return store;
};

export default configureStore;
