import { addMonths, format, isAfter, isBefore, parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { JobPublishRequest } from 'src/models/jobs/job-publish-request.model';

export const useJobPublishDates = () => {
    const now = useMemo(() => new Date(), []);
    const in2Months = useMemo(() => addMonths(new Date(), 2), []);

    return {
        now,
        in2Months,
    };
};

export const useJobDateValidation = (
    options: Pick<UseFormReturn<JobPublishRequest>, 'control' | 'setValue'>
) => {
    const now = useMemo(() => new Date(), []);
    const in2Months = useMemo(() => addMonths(new Date(), 2), []);

    const { control, setValue } = options;
    const [maxExpireDate, setMaxExpireDate] = useState(in2Months);

    const publishDateValue = useWatch({ name: 'publishDate', control, defaultValue: '' });
    const expireDateValue = useWatch({ name: 'expireDate', control, defaultValue: '' });
    const publishDate = useMemo(
        () => (publishDateValue ? parseISO(publishDateValue) : now),
        [publishDateValue, now]
    );
    const expireDate = useMemo(
        () => (expireDateValue ? parseISO(expireDateValue) : in2Months),
        [expireDateValue, in2Months]
    );
    const minExpireDate = useMemo(
        () => (publishDateValue ? parseISO(publishDateValue) : now),
        [publishDateValue, now]
    );

    useEffect(() => {
        setMaxExpireDate(addMonths(publishDate, 2));
    }, [publishDate]);

    useEffect(() => {
        if (!publishDateValue || !expireDateValue) {
            return;
        }

        if (isBefore(expireDate, publishDate)) {
            setValue('expireDate', publishDateValue);
        } else if (isAfter(expireDate, addMonths(publishDate, 2))) {
            setValue('expireDate', format(addMonths(publishDate, 2), 'yyyy-MM-dd'));
        }
    }, [publishDate, expireDate, publishDateValue, now, setValue, expireDateValue]);

    return {
        now,
        in2Months,
        minExpireDate,
        maxExpireDate,
    };
};
