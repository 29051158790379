import { Experience } from 'src/typings/api-models.generated';
import { EndorsementDisplay } from 'src/ui/features/endorsements/components/display/EndorsementDisplay';
import { VerificationDetailsSectionTitle } from '../details/VerificationDetailsSectionTitle';
import { useEndorsements } from 'src/ui/features/endorsements/hooks/endorsements.hook';
import { Loader } from 'src/ui/shared/components/loader/Loader';

const endorsementAttributeKey = 'endorsementEntityId';

interface Props {
    experience: Experience;
}

export const VerificationEndorsementDisplay = ({
    experience,
}: Props): React.ReactElement | null => {
    const fetch = useEndorsements();

    if (!experience.attributes[endorsementAttributeKey]) {
        return null;
    }

    return (
        <>
            <VerificationDetailsSectionTitle>Endorsements</VerificationDetailsSectionTitle>
            <Loader state={fetch}>
                <div className="d-flex mb-4">
                    {experience.attributes[endorsementAttributeKey].map(x => (
                        <EndorsementDisplay key={x} endorsementId={Number(x)} />
                    ))}
                </div>
            </Loader>
        </>
    );
};
