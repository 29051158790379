import { ExperienceSkillNormalized } from "src/models/verifications/verification.models";
import { VerificationDetailsSectionTitle } from "../details/VerificationDetailsSectionTitle";
import { VerificationSkillDisplay } from "./VerificationSkillDisplay";

interface Props {
    skills: ExperienceSkillNormalized[];
}

export const VerificationSkillListDisplay = ({ skills }: Props): React.ReactElement | null => {
    if (skills.length === 0) {
        return null;
    }

    return (
        <>
            <VerificationDetailsSectionTitle>Skills</VerificationDetailsSectionTitle>
            <ul className="mb-4">
                {skills.map(x => (
                    <li key={x.skill.id}>
                        <VerificationSkillDisplay normalized={x} />
                    </li>
                ))}
            </ul>
        </> 
    )
}