import { lazy, Suspense, useMemo } from 'react';
import { client } from 'src/index.helper';
import { NODE_ENV_IS_DEVELOPMENT, REACT_APP_CLIENT_STYLES } from '../environment.constants';
import { DynamicErrorBoundary } from './DynamicErrorBoundary';

const catchFunc = () => Promise.resolve({ default: () => null });

type DynamicComponentKey = 'Header' | 'Footer' | 'Logo' | 'Hero';

// this breaks single responsibility and is pretty hacky. I only allow it in
// development mode, but the logic really shouldn't be here, but it's easier...
// There's so much configuration that running "as a client" locally has become annoying
// Most of the time, you just want to view the styles, so I created a npm run client-styles helper
// however, the dynamic components are esentially part of the "client styles", so I dip into that logic
// here. Again, it's not where it should be, makes this component a little messier, introduces environment-based
// logic, etc. But it's easy, and only occurs in dev mode.
const clientKey =
    NODE_ENV_IS_DEVELOPMENT && REACT_APP_CLIENT_STYLES ? REACT_APP_CLIENT_STYLES : client;

const Logo = lazy(() => import(`../../clients/${clientKey}/Logo`).catch(catchFunc));
const Header = lazy(() => import(`../../clients/${clientKey}/Header`).catch(catchFunc));
const Hero = lazy(() => import(`../../clients/${clientKey}/Hero`).catch(catchFunc));
const Footer = lazy(() => import(`../../clients/${clientKey}/Footer`).catch(catchFunc));

interface Props {
    componentKey: DynamicComponentKey;
}

export const Dynamic = ({ componentKey }: Props): React.ReactElement => {
    const Component = useMemo(() => {
        switch (componentKey) {
            case 'Footer':
                return Footer;
            case 'Header':
                return Header;
            case 'Hero':
                return Hero;
            case 'Logo':
                return Logo;
        }
    }, [componentKey]);

    return (
        <DynamicErrorBoundary componentKey={componentKey}>
            <Suspense fallback={null}>
                <Component />
            </Suspense>
        </DynamicErrorBoundary>
    );
};
