import { VerificationSourceNormalized } from 'src/models/verifications/verification.models';
import { VerifierSourceEntity } from 'src/typings/api-models.generated';
import { SourceItemName } from './SourceItemName';
import { StudentName } from './StudentName';

interface Props {
    source: VerificationSourceNormalized;
    onSourceItemClick?: (item: VerifierSourceEntity) => void;
}

export const VerificationStudentSourceName = ({
    source,
    onSourceItemClick,
}: Props): React.ReactElement => {
    return (
        <>
            <StudentName student={source.student} />
            <span className="mx-2"></span>
            <SourceItemName item={source.entity} onClick={onSourceItemClick} />
        </>
    );
};
