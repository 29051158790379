import { Country } from '../locations/country.model';
import { Campus, CampusBuilding, CampusLocation } from '../events/campus.model';
import { CareerHubEventType } from '../events/event-type.model';
import { EmployerOption } from './employer-option.model';
import { ApiIncludeResponse, ApiResponse } from '../api/response.model';

export interface ApiConfigurationCategories {
    occupations?: EmployerOption[];
    employmentTypes?: EmployerOption[];
    organisationSizes?: EmployerOption[];
    organisationScopes?: EmployerOption[];
    industries?: EmployerOption[];
}

export interface ApiTimeZone {
    name: string;
    id: string;
    utcOffset: number;
    // introduced in 5.13 (October 2024) as a hotfix
    ianaId?: string;
}

export interface ApiConfigurationIncludes extends ApiConfigurationCategories {
    countries?: Country[];
    residencyOptions?: string[];
    campuses?: Campus[];
    campusBuildings?: CampusBuilding[];
    campusLocations?: CampusLocation[];
    // enabled in 5.9, doesn't exist in prior versions
    eventTypes?: CareerHubEventType[];
    // implemented in 5.13
    timeZones?: ApiTimeZone[];
}

export interface ApiConfigurationData {
    version: string;
    apiIncrement: number;
    hasPendingJobFix?: boolean;
    strings: EmployerStringDictionary;

    // for some reason 5.7 has an issue with "Settings", and requires an undefined check
    settings:
        | {
              identityUrl: string;
              eventQrUrlPattern: string;
              disableAgentEmployers: boolean;
              disableIndividuals: boolean;
              disableApplication: boolean;
              disableJobs?: boolean | undefined;
              disableEvents: boolean;
              disableForms?: boolean | undefined;
              disableEndorsements: boolean;
              disableCheckIn: boolean;
              disableErs?: boolean | undefined; // added in 5.17
              disableHideRecruitingFor: boolean;
              unapprovedJobLimit: number;
              unapprovedOrganisationLimit: number;
              unapprovedContactLimit: number;
              approvedContactLimit: number;
              slidingExpiryMinutes: number;
              centralBackgroundTaskEnabled: boolean;
              timeZoneId: string; // added in 5.13
              hasPendingJobFix?: boolean; // hotfixed into 5.9
              hasDisabledOrganisationFix?: boolean; // hotfixed into 5.9
              verificationOverdueDays?: number; // added in 5.17
          }
        | undefined;
    // can remove '| undefined' when everyone is on 5.10
    events:
        | {
              enableBookingList: boolean;
              disableOffCampusLocation: boolean;
              disableOnCampusLocation: boolean;
              disableOnCampusRequest: boolean;
              disableOnlineLocation: boolean;
              disableSkills: boolean;
              requestLeadDays: number;
          }
        | undefined;
}

// should probably be more specific (I do know what's coming from the backend)
export interface EmployerStringDictionary {
    'institution.name': string;
    organisation: string;
    job: string;
    contact: string;
    individual: string;
    central: string;
    jobseeker: string;
    [key: string]: string;
}

export type ApiConfigurationV1 = ApiConfigurationData & ApiConfigurationIncludes;

export type ApiConfigurationV2Response = ApiIncludeResponse<
    ApiConfigurationData,
    ApiConfigurationIncludes
>;

// Api Increment 4 (introduced in 5.13) changed the structure of this response
export type ApiConfigurationResponse = ApiResponse<ApiConfigurationV1> | ApiConfigurationV2Response;

export const apiConfigurationIncludeKeys: (keyof ApiConfigurationIncludes)[] = [
    'campusBuildings',
    'campusLocations',
    'campuses',
    'countries',
    'employmentTypes',
    'eventTypes',
    'industries',
    'occupations',
    'organisationScopes',
    'organisationSizes',
    'residencyOptions',
    'timeZones',
];
