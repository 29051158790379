import { ListGroupItem } from 'react-bootstrap';
import { VerificationNormalized } from 'src/models/verifications/verification.models';
import { verificationPathCreator } from 'src/routes/employer/paths/verification.paths';
import { VerifierSourceEntity } from 'src/typings/api-models.generated';
import { ListGroupItemHeader } from 'src/ui/shared/components/bootstrap/lists/ListGroupItemHeader';
import { VerificationStatusDisplay } from '../display/VerificationStatusDisplay';
import { ExperienceName } from '../display/ExperienceName';
import { ExperienceStartEndDate } from '../display/ExperienceStartEndDate';
import { VerificationStudentSourceName } from '../display/VerificationStudentSourceName';
import { DateDisplay } from 'src/ui/shared/components/details/DateDisplay';
import { OverdueStatusDisplay } from '../display/OverdueStatusDisplay';

interface Props {
    normalized: VerificationNormalized;
    onSourceItemClick: (item: VerifierSourceEntity) => void;
}

export const VerificationListItem = ({
    normalized: { id, experience, source, verification },
    onSourceItemClick,
}: Props): React.ReactElement => {
    return (
        <ListGroupItem>
            <ListGroupItemHeader>
                <ListGroupItemHeader.Title to={verificationPathCreator.details(id)}>
                    <ExperienceName name={experience.name} />
                </ListGroupItemHeader.Title>
                <OverdueStatusDisplay verification={verification} />
                <VerificationStatusDisplay status={verification.status} />
                <ListGroupItemHeader.Actions>
                    Requested: <DateDisplay date={verification.addedDate} />
                </ListGroupItemHeader.Actions>
            </ListGroupItemHeader>
            <div className="mb-1">
                <VerificationStudentSourceName
                    source={source}
                    onSourceItemClick={onSourceItemClick}
                />
            </div>
            <div>
                <ExperienceStartEndDate experience={experience} />
            </div>
        </ListGroupItem>
    );
};
