import { Verification, VerifierSourceEntity } from "src/typings/api-models.generated";
import { useRootSelector } from "src/ui/shared/hooks/root-selector.hook";
import { VerificationListItem } from "./VerificationListItem";

interface Props {
    verification: Verification;
    onSourceItemClick: (item: VerifierSourceEntity) => void;
}

export const VerificationListItemWrapper = ({ 
    verification, 
    onSourceItemClick 
}: Props): React.ReactElement | null => {

    const normalized = useRootSelector(state => state.verifications.normalized[verification.id]);

    // should be impossible to hit
    if (!normalized) {
        return null;
    }

    return (
        <VerificationListItem 
            normalized={normalized} 
            onSourceItemClick={onSourceItemClick} 
        />
    )
}