import { VerificationNormalized } from "src/models/verifications/verification.models";
import { DatePropertyDisplay } from "src/ui/shared/components/details/DatePropertyDisplay";
import { DetailsProperty } from "src/ui/shared/components/details/DetailsProperty";

interface Props {
    normalized: VerificationNormalized;
}

export const VerificationProperties = ({ normalized: { experience, verification } }: Props): React.ReactElement => {
    return (
        <>
            <DetailsProperty label="Verification history">
                <div className="d-table">
                    <DatePropertyDisplay label="Requested" date={experience.addedDate} />
                    <DatePropertyDisplay label="Last Updated" date={experience.lastUpdatedDate} />
                </div>
            </DetailsProperty>
            <DetailsProperty label="Experience history">
                <div className="d-table">
                    <DatePropertyDisplay label="Added" date={experience.addedDate} />
                    <DatePropertyDisplay label="Last Updated" date={experience.lastUpdatedDate} />
                </div>
            </DetailsProperty>
        </>
    )
}