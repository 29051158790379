import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { verificationVerifyAsync } from 'src/logic/features/verifications/verification.actions';
import { CardWrapper } from 'src/ui/shared/components/bootstrap/cards/CardWrapper';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';
import { VerificationButtonWrapper } from '../components/buttons/VerificationButtonWrapper';
import { VerificationDetails } from '../components/details/VerificationDetails';
import { useActiveVerificationNormalized } from '../hooks/active-verification-normalized.hook';

export const VerificationDetailsPage = (): React.ReactElement => {
    const dispatch = useDispatch();
    const { fetch, update, normalized } = useActiveVerificationNormalized();

    const onApprove = useCallback(
        (id: number) => {
            dispatch(verificationVerifyAsync.request({ id }));
        },
        [dispatch]
    );

    return (
        <ApplicationPageContent>
            <Loader state={fetch} useCard={true}>
                {normalized && (
                    <CardWrapper>
                        <VerificationDetails normalized={normalized} />
                        <div className="mt-2">
                            <VerificationButtonWrapper
                                normalized={normalized}
                                onApprove={onApprove}
                                onDeny={() => {}}
                                state={update}
                            />
                        </div>
                    </CardWrapper>
                )}
            </Loader>
        </ApplicationPageContent>
    );
};
