import keyBy from 'lodash/keyBy';
import { entityListInitialState } from 'src/logic/helpers/initial-state.helper';
import { listSingleReducerHandler } from 'src/logic/reducer-handlers/list-single.reducer-handler';
import { listReducerHandler } from 'src/logic/reducer-handlers/list.reducer-handler';
import { EntityListState } from 'src/models/store-models/entity-list-state.model';
import {
    ExperienceSkillNormalized,
    VerificationListRequest,
    VerificationNormalized,
    VerificationSourceNormalized,
} from 'src/models/verifications/verification.models';
import { EmployerVerificationInclude, Verification } from 'src/typings/api-models.generated';
import { createReducer } from 'typesafe-actions';
import * as actions from './verification.actions';

export type VerificationState = EntityListState<Verification, VerificationListRequest> & {
    normalized: {
        [id: number]: VerificationNormalized | undefined;
    };
};

const intitialState: VerificationState = {
    ...entityListInitialState,
    normalized: {},
};

const handleInclude = (
    verification: Verification,
    include: EmployerVerificationInclude
): VerificationNormalized => {
    const experience = include.experiences!.find(x => x.id === verification.experienceId)!;

    const resources = (include.resources || []).filter(
        x => x.experienceId === verification.experienceId
    );

    const allReflections = (include.reflections || [])
        .filter(x => x.experienceId === verification.experienceId)
        .filter(x => x.lastUpdatedDate === verification.lastUpdatedDate);

    const experienceReflections = allReflections.filter(
        x => x.experienceId === verification.experienceId && !x.experienceSkillId
    );

    const experienceSkills = (include.experienceSkills || []).filter(
        x => x.experienceId === verification.experienceId
    );

    const skillNormalized: ExperienceSkillNormalized[] = experienceSkills.map(experienceSkill => ({
        experienceSkill,
        reflections: allReflections
            .filter(x => x.experienceSkillId === experienceSkill.id)
            .filter(x => x.lastUpdatedDate === verification.lastUpdatedDate),
        skill: include.skills!.find(x => x.id === experienceSkill.skillId)!,
    }));

    const source = (include.sources || []).find(x => x.experienceId === verification.experienceId)!;
    const sourceNormalized: VerificationSourceNormalized = {
        source,
        entity: (include.sourceEntities || []).find(x => x.entityId === source.entityId),
        student: (include.sourceStudents || []).find(x => x.experienceId === source.experienceId),
    };

    return {
        id: verification.id,
        verification,
        experience,
        resources,
        experienceReflections,
        skills: skillNormalized,
        source: sourceNormalized,
    };
};

const verificationReducer = createReducer(intitialState)
    .handleAction(actions.verificationSetActive, listSingleReducerHandler.setActive)
    .handleAction(actions.verificationListSetActive, listReducerHandler.setActive)
    .handleAction(actions.verificationListClear, listReducerHandler.clear)

    .handleAction(actions.verificationListAsync.request, listReducerHandler.request)
    .handleAction(actions.verificationListAsync.failure, listReducerHandler.failure)
    .handleAction(actions.verificationListAsync.cancel, listReducerHandler.cancel)
    // overloading success, to handle includes
    .handleAction(actions.verificationListAsync.success, (state, action) => ({
        ...listReducerHandler.success(state, action),
        normalized: {
            ...state.normalized,
            ...keyBy(
                action.payload.data.map(x => handleInclude(x, action.payload.include)),
                x => x.id
            ),
        },
    }))

    .handleAction(actions.verificationAsync.request, listSingleReducerHandler.request)
    .handleAction(actions.verificationAsync.cancel, listSingleReducerHandler.cancel)
    .handleAction(actions.verificationAsync.failure, listSingleReducerHandler.failure)
    .handleAction(actions.verificationAsync.success, (state, action) => ({
        ...listSingleReducerHandler.success(state, action),
        normalized: {
            ...state.normalized,
            [action.payload.data.id]: handleInclude(action.payload.data, action.payload.include),
        },
    }))

    .handleAction(actions.verificationDenyAsync.request, listSingleReducerHandler.updateRequest)
    .handleAction(actions.verificationDenyAsync.failure, listSingleReducerHandler.updateFailure)
    .handleAction(actions.verificationDenyAsync.success, (state, action) => ({
        ...listSingleReducerHandler.success(state, action),
        normalized: {
            ...state.normalized,
            [action.payload.data.id]: {
                ...state.normalized[action.payload.data.id]!,
                verification: action.payload.data,
            },
        },
    }))

    .handleAction(actions.verificationVerifyAsync.request, listSingleReducerHandler.updateRequest)
    .handleAction(actions.verificationVerifyAsync.failure, listSingleReducerHandler.updateFailure)
    .handleAction(actions.verificationVerifyAsync.success, (state, action) => ({
        ...listSingleReducerHandler.success(state, action),
        normalized: {
            ...state.normalized,
            [action.payload.data.id]: {
                ...state.normalized[action.payload.data.id]!,
                verification: action.payload.data,
            },
        },
    }));

export default verificationReducer;
