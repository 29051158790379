import { Reflection } from 'src/typings/api-models.generated';
import { ReflectionSectionListDisplay } from './ReflectionSectionListDisplay';

interface Props {
    reflection: Reflection;
}

export const ReflectionDisplay = ({ reflection }: Props): React.ReactElement => {
    return (
        <div className="mb-4">
            <div className="font-weight-bold small">{reflection.prompt}</div>
            <ReflectionSectionListDisplay sections={reflection.sections} />
        </div>
    );
};
