import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { RefObject, useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ReactCropperElement } from 'react-cropper';
import { FileRejection } from 'react-dropzone';
import { useFormContext, useWatch } from 'react-hook-form';
import { useBannerImageUrl } from 'src/ui/features/events/hooks/banner-image-url.hook';
import { RejectedFileAlert } from 'src/ui/shared/components/bootstrap/alerts/RejectedFileAlert';
import {
    BANNER_RATIO,
    CropperDisplay,
} from 'src/ui/shared/components/forms/controls/cropper/CropperDisplay';
import { AlertWrapper } from '../../../bootstrap/alerts/AlertWrapper';
import { Icon } from '../../../icon/Icon';
import { HookForm } from '../../HookForm';
import { DropzoneDisplay } from '../dropzone/DropzoneDisplay';

const ACCEPTED_TYPES = ['.jpg', '.jpeg', '.png'];

interface Props {
    name: string;
    cropperRef: RefObject<ReactCropperElement>;
    onFileNameChange: (fileName: string) => void;
}

export const CropperControl = ({
    name,
    cropperRef,
    onFileNameChange,
}: Props): React.ReactElement<any, any> | null => {
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    const [dimensionsTooSmall, setDimensionsTooSmall] = useState(false);
    const { setValue, control } = useFormContext();
    const watchValue = useWatch({ name, control }) as string | undefined;
    const [rejected, setRejected] = useState<FileRejection[]>([]);

    const bannerUrl = useBannerImageUrl(watchValue, 'EventRequest');

    const onDrop = useCallback(
        (newAccepted: File[], newRejected: FileRejection[]) => {
            setRejected(newRejected);

            if (newAccepted.length > 0) {
                const url = URL.createObjectURL(newAccepted[0]);
                setImageUrl(url);
                onFileNameChange(newAccepted[0].name);
                setDimensionsTooSmall(false);
            }
        },
        [onFileNameChange]
    );

    const onUploadCancel = (reason: 'user' | 'dimensions-too-small') => {
        setImageUrl(undefined);
        if (reason === 'dimensions-too-small') {
            setDimensionsTooSmall(true);
        }
    };

    const resetValue = () => {
        setImageUrl(undefined);
        setDimensionsTooSmall(false);
        setValue(name, undefined);
    };

    return (
        <>
            <label>Cover Image</label>
            <HookForm.HiddenId name={name} forceRender />
            {rejected.length > 0 && (
                <RejectedFileAlert rejections={rejected} onDismiss={() => setRejected([])} />
            )}
            {dimensionsTooSmall && (
                <AlertWrapper
                    variant="danger"
                    icon={faExclamationTriangle}
                    dismissible={true}
                    onDismiss={() => setDimensionsTooSmall(false)}
                >
                    Minimum image dimensions: {BANNER_RATIO.width} x {BANNER_RATIO.height}
                </AlertWrapper>
            )}
            {imageUrl === undefined && !watchValue && (
                <DropzoneDisplay
                    options={{
                        onDrop,
                        maxSize: 4194304, // 4mbs
                        accept: ACCEPTED_TYPES,
                        multiple: false,
                    }}
                >
                    <div className="mb-2">
                        Upload a cover image <small>(Click or drop files)</small>
                    </div>
                    <small>
                        <div>Accepted file types: jpg, jpeg, png.</div>
                        <div>Max size: 4mbs</div>
                        <div>
                            Minimum dimensions: {BANNER_RATIO.width} x {BANNER_RATIO.height}
                        </div>
                    </small>
                </DropzoneDisplay>
            )}
            {imageUrl && !watchValue && (
                <CropperDisplay
                    onCancel={onUploadCancel}
                    imageUrl={imageUrl}
                    cropperRef={cropperRef}
                />
            )}
            {watchValue && (
                <div>
                    <img
                        style={{
                            width: '100%',
                            maxWidth: `${BANNER_RATIO.width}px`,
                            objectFit: 'cover',
                        }}
                        className="mx-auto"
                        src={bannerUrl}
                    />
                    <div style={{ maxWidth: `${BANNER_RATIO.width}px` }} className="text-right">
                        <Button variant="link-danger" onClick={resetValue}>
                            <Icon icon={faTimes}>Clear image</Icon>
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
