import { EntityDetailsTitle } from 'src/ui/shared/components/titles/EntityDetailsTitle';
import { useActiveVerificationNormalized } from '../../hooks/active-verification-normalized.hook';
import { VerificationListBreadcrumb } from '../breadcrumbs/VerificationListBreadcrumb';

export const VerificationDetailsPageNav = (): React.ReactElement | null => {
    const { fetch, update, normalized } = useActiveVerificationNormalized();

    if (!normalized) {
        return null;
    }

    return (
        <EntityDetailsTitle
            title={normalized.experience.name || 'Unknown experience'}
            hideLoader={!!(fetch.error || update.error)}
            before={<VerificationListBreadcrumb />}
        />
    );
};
