import {
    BBox2D,
    FieldConfiguration,
    FieldName,
    PeliasFeature,
    PeliasQuery,
} from '@symplicity/geocoding';
import { cloneDeep, isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { LocationModel } from 'src/models/locations/location.model';
import { HookFormRules } from 'src/ui/shared/components/forms/HookForm';
import { CountrySelectControl } from 'src/ui/shared/components/forms/controls/react-selects/CountrySelectControl';
import { TypeaheadOption } from 'src/ui/shared/components/forms/controls/react-selects/TypeaheadControl';
import { createMaxLengthRule } from 'src/ui/shared/helpers/validation.helper';
import { bboxToGeolocation, geolocationToBBox } from '../helpers/geolocation.helpers';
import { useCountryOption } from '../hooks/useCountryOption';
import {
    LocationAutocomplete,
    LocationAutocompleteProps,
    LocationOption,
} from './LocationAutocomplete';
import { SingleValue } from 'react-select';
const { getBoundary, getFocus } = PeliasQuery;

interface LocationForm {
    countryCode?: string;
    region?: LocationOption | null;
    city?: LocationOption | null;
    postcode?: LocationOption | null;
    street?: LocationOption | null;
    bbox?: BBox2D | null;
}

interface SharedProps {
    name?: string;
    tabIndex?: number;
    disabled?: boolean;
    autoFocus?: boolean;
    removeLabel?: boolean;
}

interface AutocompleteProps extends SharedProps, LocationAutocompleteProps {
    name: FieldName;
    countryCode: string | undefined;
    required?: boolean;
}

interface LocationFieldsProps extends SharedProps {
    required?: boolean;
    defaultValues: LocationModel;
    onChange?: (value: LocationModel) => void;
}

const createOption = (value: string | undefined): LocationOption | null =>
    value ? { created: true, value } : null;

const countrySelectRules = {
    required: true,
    maxLength: createMaxLengthRule(2),
};

const LocationAutocompleteWrapper = (props: AutocompleteProps) => {
    const { name, countryCode, required } = props;
    const fieldConfig = useMemo(
        () => FieldConfiguration.get(countryCode, name),
        [countryCode, name]
    );
    const { maxLength } = fieldConfig;

    const rules = useMemo(
        () => ({
            required: required,
            validate: (val: LocationOption) => {
                return val?.value?.length > maxLength
                    ? `This field must be ${maxLength} characters or less.`
                    : undefined;
            },
        }),
        [maxLength, required]
    );

    return (
        <LocationAutocomplete
            {...fieldConfig}
            {...props}
            getValue={props.getValue || fieldConfig.getValue}
            rules={rules}
            disabled={props.disabled || !countryCode}
        />
    );
};

export const LocationFields = (props: LocationFieldsProps) => {
    const { defaultValues, tabIndex, autoFocus, disabled, removeLabel, required, onChange } = props;
    const context = useFormContext();

    // setup nested form
    const form = useForm<LocationForm>({
        mode: 'onChange',
        defaultValues: {
            countryCode: defaultValues.countryCode,
            region: createOption(defaultValues.region),
            city: createOption(defaultValues.city),
            postcode: createOption(defaultValues.postcode),
            street: createOption(defaultValues.street),
            bbox: defaultValues.geolocation
                ? geolocationToBBox(defaultValues.geolocation)
                : undefined,
        },
    });

    const { watch, setValue, trigger } = form;
    const [countryCode, region, city, postcode, street] = watch([
        'countryCode',
        'region',
        'city',
        'postcode',
        'street',
    ]);
    const countryOption = useCountryOption(countryCode);

    const setContextValue = useCallback(
        (propName: keyof LocationModel, value: any) => {
            context.setValue(props.name ? `${props.name}.${propName}` : propName, value);
        },
        [context, props.name]
    );

    // clear all location fields on country change
    const countryOnChange = useCallback(
        (val: SingleValue<TypeaheadOption>) => {
            ['region', 'city', 'postcode', 'street'].forEach((name: any) => {
                setValue(name, null);
                setContextValue(name, '');
            });

            setContextValue('countryCode', val?.value);
        },
        [setContextValue, setValue]
    );

    // auto-populate fields from pelias data
    const setOption = useCallback(
        (name: FieldName & keyof LocationForm, option?: LocationOption | null) => {
            const value = watch(name) as LocationOption;
            const { getValue, layers } = FieldConfiguration.get(countryCode, name);

            // Don't clear fields if this option is manually created
            if (option?.created) {
                return;
            }

            // Only auto-populate the field if it is empty or was auto-populated
            // ie. do not auto-populate if the value was manually entered by the user
            if (value && !value.effect) {
                return;
            }

            const text = getValue(option?.feature);
            console.log('get value text', option?.feature, text);

            // If there is nothing to auto-populate clear the field
            if (!text) {
                setValue(name, null);
                setContextValue(name, null);
                return;
            }

            const gid = PeliasFeature.findGid(option!.feature, layers);
            const result: LocationOption = {
                value: text,
                effect: true,
                feature: {
                    type: 'Feature',
                    id: gid || text,
                    properties: { ...option!.feature!.properties!, gid: gid! },
                },
            };

            setValue(name, result);
            setContextValue(name, text);
        },
        [watch, countryCode, setValue, setContextValue]
    );

    // auto-populate callbacks
    const regionOnChange = useCallback(
        (val: LocationOption | null) => {
            setContextValue('region', val?.value);
        },
        [setContextValue]
    );

    const cityOnChange = useCallback(
        (val: LocationOption | null) => {
            console.log('cityOnChange', val);
            setOption('region', val);
            setContextValue('city', val?.value);
        },
        [setContextValue, setOption]
    );

    const postcodeOnChange = useCallback(
        (val: LocationOption | null) => {
            setOption('region', val);
            setOption('city', val);
            setContextValue('postcode', val?.value);
        },
        [setContextValue, setOption]
    );

    const streetOnChange = useCallback(
        (val: LocationOption | null) => {
            setOption('region', val);
            setOption('city', val);
            setOption('postcode', val);
            setContextValue('street', val?.value);
        },
        [setContextValue, setOption]
    );

    // props.onChange callback
    const [model, setModel] = useState(cloneDeep(defaultValues));

    useEffect(() => {
        const bboxes = [street, postcode, city, region]
            .map(x => x?.feature)
            .map(PeliasFeature.toBBox)
            .filter(x => !!x);
        const countryBBox = countryOption?.geolocation
            ? geolocationToBBox(countryOption.geolocation)
            : undefined;
        const bbox = BBox2D.intersection(...bboxes) || bboxes[0] || countryBBox;
        const geolocation = bbox ? bboxToGeolocation(bbox) : undefined;
        const newModel = {
            street: street?.value,
            postcode: postcode?.value,
            city: city?.value,
            region: region?.value,
            countryCode: countryOption?.code,
            geolocation,
        };

        if (!isEqual(newModel, model)) {
            setModel(newModel);
            setContextValue('geolocation', geolocation);
            onChange?.(newModel);
        }
    }, [street, postcode, city, region, countryOption, setContextValue, onChange, model]);

    // register fields in parent form
    useEffect(() => {
        const reg = (f: string, rules?: HookFormRules) =>
            context.register(props.name ? `${props.name}.${f}` : f, rules);
        reg('region', { required, maxLength: createMaxLengthRule(100) });
        reg('city', { required, maxLength: createMaxLengthRule(60) });
        reg('postcode', { required, maxLength: createMaxLengthRule(10) });
        reg('street', { required, maxLength: createMaxLengthRule(300) });
        reg('countryCode', { required });
        reg('geolocation');
    }, [context, props.name, required]);

    // trigger nested form validation when the parent form is submitted
    useEffect(() => {
        if (context.formState.isSubmitted) {
            void trigger();
        }
    }, [context.formState.isSubmitted, trigger]);

    // Use memos for bounday / focus to avoid unnecessary renders
    const regionBoundary = useMemo(() => getBoundary(countryCode), [countryCode]);
    const cityBoundary = useMemo(
        () => getBoundary(countryCode, region?.feature),
        [countryCode, region?.feature]
    );
    const cityFocus = useMemo(() => getFocus(postcode?.feature), [postcode?.feature]);
    const postcodeBoundary = useMemo(
        () =>
            getBoundary(
                countryCode,
                region?.feature,
                countryCode === 'GB' ? city?.feature : undefined
            ),
        [city?.feature, countryCode, region?.feature]
    );
    const postcodeFocus = useMemo(() => getFocus(city?.feature), [city?.feature]);
    const streetBoundary = useMemo(
        () => getBoundary(countryCode, region?.feature, city?.feature, postcode?.feature),
        [city?.feature, countryCode, postcode?.feature, region?.feature]
    );
    const streetFocus = useMemo(
        () => getFocus(postcode?.feature, city?.feature),
        [city?.feature, postcode?.feature]
    );

    return (
        <FormProvider {...form}>
            <Form.Row>
                <Col lg={6}>
                    <CountrySelectControl
                        name="countryCode"
                        label="Country"
                        placeholder="Country"
                        rules={countrySelectRules}
                        removeLabel={removeLabel}
                        tabIndex={tabIndex}
                        autoFocus={autoFocus && !focus}
                        disabled={disabled}
                        onChange={countryOnChange}
                    />
                </Col>
                <Col lg={6}>
                    <LocationAutocompleteWrapper
                        {...props}
                        name="region"
                        countryCode={countryCode}
                        onChange={regionOnChange}
                        boundary={regionBoundary}
                    />
                </Col>
                <Col lg={6}>
                    <LocationAutocompleteWrapper
                        {...props}
                        name="city"
                        countryCode={countryCode}
                        onChange={cityOnChange}
                        boundary={cityBoundary}
                        focus={cityFocus}
                    />
                </Col>
                <Col lg={6}>
                    <LocationAutocompleteWrapper
                        {...props}
                        name="postcode"
                        countryCode={countryCode}
                        onChange={postcodeOnChange}
                        boundary={postcodeBoundary}
                        focus={postcodeFocus}
                    />
                </Col>
            </Form.Row>

            <LocationAutocompleteWrapper
                {...props}
                name="street"
                countryCode={countryCode}
                onChange={streetOnChange}
                boundary={streetBoundary}
                focus={streetFocus}
                getValue={feature => feature.properties.name}
            />
        </FormProvider>
    );
};
