import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FileRejection } from 'react-dropzone';
import { RejectedFileAlert } from '../../../bootstrap/alerts/RejectedFileAlert';
import { Icon } from '../../../icon/Icon';
import { DropzoneDisplay } from '../dropzone/DropzoneDisplay';

const ACCEPTED_TYPES = ['.jpg', '.jpeg', '.png'];

const determineIfHasAlpha = (context: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => {
    const data = context.getImageData(0, 0, canvas.width, canvas.height).data;
    let hasAlphaPixels = false;
    for (let i = 3, n = data.length; i < n; i += 4) {
        if (data[i] < 255) {
            hasAlphaPixels = true;
            break;
        }
    }
    return hasAlphaPixels;
};

const getFileNameWithoutExt = ({ name }: File) => {
    const periodIndex = name.lastIndexOf('.');
    return name.substring(0, periodIndex < 0 ? name.length : periodIndex);
};

const resizeImage = async (file: File, width: number, height: number) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;

    const img = await createImageBitmap(file);

    // if both the width and the height are smaller than the desired width/height
    // just use the original image dimensions (don't attempt to upsize)
    const dontResize = img.width < width && img.height < height;

    // Set scale to fit image to canvas,
    const scale = dontResize ? 1 : Math.min(width / img.width, height / img.height);

    // Set new image dimensions.
    const scaledWidth = img.width * scale;
    const scaledHeight = img.height * scale;

    canvas.width = scaledWidth;
    canvas.height = scaledHeight;

    ctx.drawImage(img, 0, 0, scaledWidth, scaledHeight);
    const hasAlpha = determineIfHasAlpha(ctx, canvas);
    const fileNameWithoutExt = getFileNameWithoutExt(file);

    const pngBlob = await new Promise<Blob>(resolve => {
        canvas.toBlob(blob => resolve(blob!));
    });

    const png = new File([pngBlob], fileNameWithoutExt + '.png', { type: 'image/png' });

    if (hasAlpha) {
        return png;
    }

    const jpgBlob = await new Promise<Blob>(resolve => {
        canvas.toBlob(blob => resolve(blob!), 'image/jpeg', 0.9);
    });

    return png.size < jpgBlob.size
        ? png
        : new File([jpgBlob], fileNameWithoutExt + '.jpg', { type: 'image/jpeg' });
};

interface Props {
    onAction: (blob: File | undefined) => void;
    children?: React.ReactNode;
    width: number;
    height: number;
    imageWidth?: number;
    imageHeight?: number;
    defaultImageUrl?: string;
    disabled?: boolean;
}

export const SingleImageDropzone = ({
    onAction,
    width,
    height,
    imageWidth,
    imageHeight,
    children,
    defaultImageUrl,
    disabled,
}: Props): React.ReactElement<any, any> => {
    const [rejected, setRejected] = useState<FileRejection[]>([]);
    const [imageUrl, setImageUrl] = useState<string | undefined>(defaultImageUrl);

    const onDrop = useCallback(
        async (newAccepted: File[], newRejected: FileRejection[]) => {
            setRejected(newRejected);

            if (newAccepted.length === 0) {
                return;
            }
            const accepted = newAccepted[0];
            const file = await resizeImage(accepted, imageWidth || width, imageHeight || height);
            const url = URL.createObjectURL(file);
            setImageUrl(url);
            onAction(file);
        },
        [height, imageHeight, imageWidth, onAction, width]
    );

    const onClear = () => {
        setImageUrl(undefined);
        onAction(undefined);
    };

    return (
        <>
            <RejectedFileAlert rejections={rejected} onDismiss={() => setRejected([])} />
            <div style={{ width: `${width}px`, height: `${height}px` }}>
                {!imageUrl && (
                    <OverlayTrigger
                        placement="right"
                        overlay={porps => (
                            <Tooltip id="org-logo-tooltip" {...porps}>
                                {disabled ? (
                                    <>This field cannot be edited</>
                                ) : (
                                    <>
                                        <div>Add your organisation logo</div>
                                        <div>(click or drag files)</div>
                                    </>
                                )}
                            </Tooltip>
                        )}
                    >
                        <a className="w-100 h-100">
                            <DropzoneDisplay
                                options={{
                                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                    onDrop,
                                    accept: ACCEPTED_TYPES,
                                    multiple: false,
                                    disabled,
                                }}
                            >
                                {children}
                            </DropzoneDisplay>
                        </a>
                    </OverlayTrigger>
                )}
                {imageUrl && !disabled && (
                    <OverlayTrigger
                        placement="right"
                        overlay={porps => (
                            <Tooltip id="org-logo-tooltip" {...porps}>
                                <div>
                                    <Icon icon={faTimes} />
                                    <span className="ml-1">Click to clear this image.</span>
                                </div>
                            </Tooltip>
                        )}
                    >
                        <a onClick={onClear} style={{ cursor: 'pointer' }}>
                            <img
                                style={{ maxWidth: `${width}px`, maxHeight: `${height}px` }}
                                src={imageUrl}
                            />
                        </a>
                    </OverlayTrigger>
                )}
                {imageUrl && disabled && (
                    <img
                        style={{ maxWidth: `${width}px`, maxHeight: `${height}px` }}
                        src={imageUrl}
                    />
                )}
            </div>
        </>
    );
};
