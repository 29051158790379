import { LocationChangeAction } from 'connected-react-router';
import { Location } from 'history';
import { matchPath, RouteProps } from 'react-router-dom';

export const getRouteMatch = <TParams extends { [K in keyof TParams]?: string }>(
    location: Location<any>,
    path: string | string[] | RouteProps
) => {
    return matchPath<TParams>(location.pathname, path);
};

export const isRouteMatch = (location: Location<any>, paths: string | string[] | RouteProps) =>
    !!getRouteMatch(location, paths);

export const isLocationRouteMatch =
    (paths: string | string[] | RouteProps) => (action: LocationChangeAction<any>) =>
        isRouteMatch(action.payload.location, paths);
