import { ExperienceResource } from 'src/typings/api-models.generated';
import { VerificationDetailsSectionTitle } from '../details/VerificationDetailsSectionTitle';
import { VerificationResourceDisplay } from './VerificationResourceDisplay';

interface Props {
    resources: ExperienceResource[];
}

export const VerificationResourceListDisplay = ({
    resources,
}: Props): React.ReactElement | null => {
    if (resources.length === 0) {
        return null;
    }

    return (
        <>
            <VerificationDetailsSectionTitle>Resources</VerificationDetailsSectionTitle>
            <div className="ml-4 mb-4">
                {resources.map(x => (
                    <div key={x.id}>
                        <VerificationResourceDisplay resource={x} />
                    </div>
                ))}
            </div>
        </>
    );
};
