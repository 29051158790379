import { ExperienceSkillNormalized } from "src/models/verifications/verification.models"
import { VerificationDetailsSectionTitle } from "../details/VerificationDetailsSectionTitle";
import { ReflectionDisplay } from "./ReflectionDisplay";
import { ExperienceSkillRankDisplay } from "./ExperienceSkillRankDisplay";

interface Props {
    normalized: ExperienceSkillNormalized;
}

export const VerificationSkillReflectionDisplay = ({ normalized }: Props): React.ReactElement | null => {

    if (normalized.reflections.length === 0) {
        return null;
    }

    return (
        <>
            <VerificationDetailsSectionTitle>
                <span className="mr-2">Reflections on skill: {normalized.skill.name}</span>
                <ExperienceSkillRankDisplay experienceSkill={normalized.experienceSkill} />
            </VerificationDetailsSectionTitle>
            <div className="pl-3 mb-4 border-left">
                {normalized.reflections.map(x => (
                    <ReflectionDisplay key={x.id}  reflection={x} />
                ))}
            </div>
        </>
    )
}