import lodashGet from 'lodash/get';
import { useEffect } from 'react';
import { InputGroup } from 'react-bootstrap';
import { Field, FormProvider, useForm, useFormContext } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
    SocialProperty,
    SocialType,
    SOCIAL_TYPE_META,
} from '../../../../../models/organisation/social-property.model';
import { Icon } from '../../icon/Icon';
import { HookInputControlProps, InputControl } from './InputControl';

interface HookSocialControlProps extends HookInputControlProps {
    socialType: SocialType;
    defaultValue: SocialProperty | undefined;
}

export const SocialControl = (
    props: HookSocialControlProps
): React.ReactElement<any, any> | null => {
    const { socialType, name, rules, defaultValue } = props;

    const meta = SOCIAL_TYPE_META[socialType];

    const { register, setValue, control } = useFormContext();

    // this is a bit dumb,
    // for this to work you have to "know" that the name is using an array syntax
    // e.g. "socialProperty[2]", and that the name is targetting the control
    // using this syntax
    const innerRef = lodashGet(control._fields, name);

    useDeepCompareEffect(() => {
        register(name, rules);
    }, [register, name, rules]);

    const innerName = 'inner-' + socialType;
    const innerMethods = useForm({
        defaultValues: {
            [innerName]: defaultValue?.value,
        },
    });

    const innerValue = innerMethods.watch(innerName);

    useEffect(() => {
        const valueToSave: SocialProperty | undefined = innerValue
            ? {
                  socialType,
                  value: innerValue,
              }
            : undefined;

        setValue(name, valueToSave, {
            shouldDirty: true,
            shouldValidate: true,
        });
    }, [innerValue, setValue, name, socialType]);

    return (
        <FormProvider {...innerMethods}>
            <InputControl
                {...props}
                label={<>&nbsp;</>}
                disabled={props.disabled || (innerRef as Field | undefined)?._f.ref.disabled}
                name={innerName}
                prepend={
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <Icon icon={meta.icon} size="lg" />
                            {meta.inputPrefix && <span className="pl-2">{meta.inputPrefix}</span>}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                }
                placeholder={meta.placeholder}
            />
        </FormProvider>
    );
};
