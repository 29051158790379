import { asyncEpicBase } from "src/logic/helpers/epics/async.epic-helper";
import * as actions from './option.actions';

export const optionQueryEpic = asyncEpicBase(
    actions.optionQueryAsync,
    ({ api }, { payload }) => api.options.query(payload),
    {
        success: (result, ra) => actions.optionQueryAsync.success(result.json, ra.payload),
        failure: (error, requestAction) => actions.optionQueryAsync.failure(error, requestAction.payload),
    }
);

export const optionValuesEpic = asyncEpicBase(
    actions.optionValuesAsync,
    ({ api }, { payload }) => api.options.values(payload),
    {
        success: (result, ra) => actions.optionValuesAsync.success(result.json, ra.payload),
        failure: (error, requestAction) => actions.optionValuesAsync.failure(error, requestAction.payload),
    }
);