import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { DefaultValues, FieldValues, useForm } from 'react-hook-form';
import { ErrorNormalized } from '../../../models/errors/error.model';
import { useFormServerValidation } from './form-server-validation.hook';

export const useFormDefault = <TModel extends FieldValues = FieldValues>(options: {
    defaultValues: DefaultValues<TModel> | undefined;
    error: ErrorNormalized | undefined;
    disabled?: boolean;
    zod?: Parameters<typeof zodResolver>[0];
}) => {
    const formMethods = useForm<TModel, any>({
        defaultValues: options.defaultValues,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: options.zod ? zodResolver(options.zod) : undefined,
    });

    const fields = formMethods.control._fields;

    // todo: clean this up!
    useEffect(() => {
        if (options.disabled) {
            Object.keys(fields).forEach(key => {
                const field = fields[key];
                const fieldArray = Array.isArray(field) ? field : [field];

                fieldArray.forEach(inner => {
                    const innerRef = inner?._f.ref;

                    if (innerRef) {
                        innerRef.disabled = true;
                    }
                });
            });
        }
    }, [fields, options.disabled]);

    useFormServerValidation(options.error, formMethods.setError);

    return { ...formMethods, zod: options.zod };
};
