import values from 'lodash/values';
import { useMemo } from 'react';
import { Col, Form } from 'react-bootstrap';
import Select, { MultiValue } from 'react-select';
import { useIdentityContact } from 'src/ui/features/authentication/hooks/identity-contact.hook';
import { useContactSelectOptions } from 'src/ui/shared/components/forms/controls/react-selects/ContactSelectControl';
import { ReactSelectOption } from 'src/ui/shared/components/forms/controls/react-selects/SingleSelectControl';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useCentralAlreadyPublished } from '../../hooks/central-already-published.hook';
import { CentralPublishSectionTitle } from '../publish/CentralPublishSectionTitle';

interface Props {
    onUpdate: (ids: number[]) => void;
    organisationId: number;
}

export const CentralPublishContactInviteInfo = ({
    onUpdate,
    organisationId,
}: Props): React.ReactElement<any, any> | null => {
    // there is a massive assumption here that ALL contacts have already been loaded
    const contactItems = useRootSelector(state => state.contacts.items);
    const contacts = values(contactItems)
        .filter(i => i !== undefined)
        .map(i => i!);
    const { identityContactId } = useIdentityContact();

    const activeContactsNotMe = useMemo(() => {
        return contacts.filter(i => i.id !== identityContactId).filter(i => i.active);
    }, [contacts, identityContactId]);

    const options = useContactSelectOptions(activeContactsNotMe);
    const orgAlreadyPublished = useCentralAlreadyPublished(organisationId);

    const { currentStep, contactInviteState } = useRootSelector(state => state.central);
    const states = values(contactInviteState)
        .filter(i => i !== undefined)
        .map(i => i!);

    const loading = useMemo(() => states.some(i => i.loading), [states]);

    const complete = useMemo(
        () =>
            (currentStep.step !== 'contactInvite' && orgAlreadyPublished) ||
            (!loading && states.some(i => i.error)),
        [currentStep.step, orgAlreadyPublished, loading, states]
    );

    // this is all a bit hacky
    const onChange = (data: MultiValue<ReactSelectOption>) => {
        const ids = data.map(i => Number(i.value)) || [];
        onUpdate(ids);
    };

    if (activeContactsNotMe.length === 0 || complete) {
        return null;
    }

    return (
        <>
            <CentralPublishSectionTitle
                state={states}
                complete={complete}
                titleClassName="d-inline mb-2"
            >
                Contact Invites
            </CentralPublishSectionTitle>
            {!loading && !complete && (
                <>
                    <ul>
                        <li>
                            Existing contacts can be invited to the CareerHub Central organisation.
                        </li>
                    </ul>
                    <Form.Row>
                        <Col xs={12} lg={6}>
                            <Select
                                isDisabled={loading}
                                options={options}
                                isMulti={true}
                                onChange={onChange}
                                classNamePrefix="react-select"
                                className="react-select-container"
                            />
                        </Col>
                    </Form.Row>
                </>
            )}
            <hr />
        </>
    );
};
