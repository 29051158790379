import { useEffect } from 'react';
import { useEndorsementsIsEnabled } from './endorsements-is-enabled.hook';
import { useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { endorsementAllAsync } from 'src/logic/features/endorsements/endorsement.actions';

// ToDo: move to epics
export const useEndorsements = () => {
    const dispatch = useDispatch();
    const { isEnabled } = useEndorsementsIsEnabled();
    const { fetch, fetched } = useRootSelector(state => state.endorsements.all);

    useEffect(() => {
        if (!isEnabled) {
            return;
        }

        if (fetch.loading || fetched) {
            return;
        }

        dispatch(endorsementAllAsync.request());
    }, [dispatch, fetch.loading, fetched, isEnabled]);

    return fetch;
};
