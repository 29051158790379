import { Badge } from "react-bootstrap";
import { VerificationStatus } from "src/typings/api-models.generated";

interface Props {
    status: VerificationStatus;
}

export const VerificationStatusDisplay = ({ status }: Props): React.ReactElement | null => {

    switch(status) {
        case 'default': return null;
        case 'pending': return <Badge variant="outline-info">Pending verification</Badge>
        case 'denied': return <Badge variant="outline-danger">Denied</Badge>
        case 'verified': return <Badge variant="outline-success">Verified</Badge>
    }
}