import { EntityRequest, EntityRequestOrUndefined } from 'src/models/api/request.model';
import { ApiIncludeResponse, ApiResponse, ApiSearchIncludeResponse } from 'src/models/api/response.model';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { ActiveListState } from 'src/models/store-models/entity-list-state.model';
import { VerificationListRequest } from 'src/models/verifications/verification.models';
import { EmployerVerificationInclude, Verification, VerificationDenyModel } from 'src/typings/api-models.generated';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const verificationResetState = createAction('VERIFICATION_RESET_STATE')();

export const verificationSetActive =
    createAction('VERIFICATION:SET_ACTIVE')<EntityRequestOrUndefined>();
export const verificationListSetActive = createAction('VERIFICATION_LIST:SET_ACTIVE')<
    ActiveListState<VerificationListRequest>
>();
export const verificationListClear = createAction('VERIFICATION_LIST:CLEAR')<{
    excludeActive: boolean;
}>();

export const verificationListAsync = createAsyncAction(
    'VERIFICATION_LIST:REQUEST',
    'VERIFICATION_LIST:SUCCESS',
    'VERIFICATION_LIST:FAILURE',
    'VERIFICATION_LIST:CANCEL'
)<
    VerificationListRequest,
    ApiSearchIncludeResponse<Verification, VerificationListRequest, EmployerVerificationInclude>,
    [ErrorNormalized, VerificationListRequest],
    undefined
>();

export const verificationAsync = createAsyncAction(
    'VERIFICATION:REQUEST',
    'VERIFICATION:SUCCESS',
    'VERIFICATION:FAILURE',
    'VERIFICATION:CANCEL'
)<EntityRequest, ApiIncludeResponse<Verification, EmployerVerificationInclude>, [ErrorNormalized, EntityRequest], EntityRequest>();

export const verificationVerifyAsync = createAsyncAction(
    'VERIFICATION_VERIFY:REQUEST',
    'VERIFICATION_VERIFY:SUCCESS',
    'VERIFICATION_VERIFY:FAILURE',
)<EntityRequest, ApiResponse<Verification>, [ErrorNormalized, EntityRequest]>();

export const verificationDenyAsync = createAsyncAction(
    'VERIFICATION_DENY:REQUEST',
    'VERIFICATION_DENY:SUCCESS',
    'VERIFICATION_DENY:FAILURE',
)<EntityRequest & VerificationDenyModel, ApiResponse<Verification>, [ErrorNormalized, EntityRequest & VerificationDenyModel]>();