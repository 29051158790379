import { useState } from "react";
import { Button } from "react-bootstrap";
import { LoaderState } from "src/models/errors/error.model";
import { VerificationNormalized } from "src/models/verifications/verification.models";
import { LoaderSpinner } from "src/ui/shared/components/loader/LoaderSpinner";
import { VerificationDenyFormModal } from "../forms/VerificationDenyFormModal";
import { HookFormErrorAlert } from "src/ui/shared/components/forms/shared/HookFormErrorAlert";

interface Props {
    normalized: VerificationNormalized;
    onApprove: (id: number) => void;
    onDeny: (id: number, feedback: string) => void;
    state: LoaderState;
}

export const VerificationButtonWrapper = ({ normalized, onApprove, onDeny, state }: Props): React.ReactElement | null => {

    const [show, setShow] = useState(false);

    if (normalized.verification.status !== 'pending') {
        return null;
    }

    return (
        <div>
            <HookFormErrorAlert error={state.error} />
            <div>
                <Button disabled={state.loading} variant="primary" className="mx-2" onClick={() => onApprove(normalized.id)}>Verify details</Button>
                <Button disabled={state.loading} variant="outline-danger" onClick={() => setShow(true)}>Deny</Button>
                {state.loading && (<LoaderSpinner size="sm" spinnerClassName="ml-2" />)}
            </div>
            {show && (<VerificationDenyFormModal verificationId={normalized.id} onCancel={() => setShow(false)} />)}
        </div>
    )
}