import { push } from 'connected-react-router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { homePath } from 'src/routes/layouts/HomeRouteLayout';

export const useContentAnchorOverride = () => {
    const dispatch = useDispatch();

    const onClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (
                event.target instanceof HTMLAnchorElement &&
                (event.target.href.startsWith(window.origin) ||
                    event.target.href.startsWith(homePath))
            ) {
                event.preventDefault();

                const href = event.target.href;
                const path = href.startsWith(window.origin)
                    ? href.replace(window.origin, '')
                    : href;

                const normalisedPath = path === '' ? homePath : path;

                dispatch(push(normalisedPath));
            }
        },
        [dispatch]
    );

    return { onClick };
};
