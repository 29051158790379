import { push } from 'connected-react-router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { verificationPathCreator } from 'src/routes/employer/paths/verification.paths';
import {
    Verification,
    VerificationStatus,
    VerifierSourceEntity,
} from 'src/typings/api-models.generated';
import { ListGroupWrapper } from 'src/ui/shared/components/lists/ListGroupWrapper';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ApplicationPageContent } from 'src/ui/shared/components/page-components/ApplicationPageContent';
import { ListPaginationWrapper } from 'src/ui/shared/components/page-components/ListPaginationWrapper';
import { useActiveEntityList } from 'src/ui/shared/hooks/active-entity-list.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { VerificationSourceItemFilter } from '../components/forms/VerificationSourceItemFilter';
import { VerificationStatusFilter } from '../components/forms/VerificationStatusFilter';
import { VerificationListItemWrapper } from '../components/list/VerificationListWrapper';

export const VerificationListPage = (): React.ReactElement => {
    const {
        entities: verifications,
        fetch,
        pagination,
    } = useActiveEntityList<Verification>(state => state.verifications);
    const activeQuery = useRootSelector(state => state.verifications.activeList.query);
    const locationQuery = useRootSelector(state => state.router.location.query);

    const dispatch = useDispatch();
    const onSourceItemClick = (item: VerifierSourceEntity) => {
        switch (item.type.toLowerCase()) {
            case 'activity': {
                const path = verificationPathCreator.list({
                    ...locationQuery,
                    activities: [item.entityId],
                    events: undefined,
                });
                dispatch(push(path));
                break;
            }
            case 'event': {
                const path = verificationPathCreator.list({
                    ...locationQuery,
                    events: [item.entityId],
                    activities: undefined,
                });
                dispatch(push(path));
                break;
            }
        }
    };

    const onSetStatus = useCallback(
        (status: Capitalize<VerificationStatus>) => {
            const path = verificationPathCreator.list({ ...locationQuery, status: [status] });
            dispatch(push(path));
        },
        [dispatch, locationQuery]
    );

    return (
        <ApplicationPageContent>
            <ListPaginationWrapper pagination={pagination}>
                <div className="mr-4 mb-3 mb-md-0">
                    <VerificationStatusFilter
                        active={activeQuery.status || []}
                        onClick={onSetStatus}
                    />
                </div>
                <div style={{ minWidth: '300px' }}>
                    <VerificationSourceItemFilter />
                </div>
            </ListPaginationWrapper>
            <Loader state={fetch} useCard={true}>
                <ListGroupWrapper items={verifications}>
                    {verifications.map(verification => (
                        <VerificationListItemWrapper
                            key={verification.id}
                            verification={verification}
                            onSourceItemClick={onSourceItemClick}
                        />
                    ))}
                </ListGroupWrapper>
            </Loader>
        </ApplicationPageContent>
    );
};
