import { VerificationNormalized } from 'src/models/verifications/verification.models';
import { ExperienceName } from '../display/ExperienceName';
import { ExperienceStartEndDate } from '../display/ExperienceStartEndDate';
import { VerificationStatusDisplay } from '../display/VerificationStatusDisplay';
import { VerificationStudentSourceName } from '../display/VerificationStudentSourceName';
import { OverdueStatusDisplay } from '../display/OverdueStatusDisplay';

interface Props {
    normalized: VerificationNormalized;
}

export const VerificationDetailsHeader = ({
    normalized: { experience, source, verification },
}: Props): React.ReactElement => {
    return (
        <>
            <div className="d-flex mb-2 align-items-top">
                <h2 className="mb-0 mr-2">
                    <ExperienceName name={experience.name} />
                </h2>
                <OverdueStatusDisplay verification={verification} />
                <div className="mr-2">
                    <VerificationStatusDisplay status={verification.status} />
                </div>
            </div>
            <div className="mb-1">
                <VerificationStudentSourceName source={source} />
            </div>
            <div>
                <ExperienceStartEndDate experience={experience} />
            </div>
        </>
    );
};
