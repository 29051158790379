import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { authenticationLogoutSelected } from '../../../../logic/features/authentication/authentication.actions';
import { logError } from '../../sentry/helpers/sentry.helper';
import { ErrorNormalized } from 'src/models/errors/error.model';

export const useLogout = () => {
    const dispatch = useDispatch();
    const logoutTriggered = useRootSelector(state => state.authentication.logoutSelected);

    const logout = useCallback(() => {
        dispatch(authenticationLogoutSelected());
    }, [dispatch]);

    const clientCode = useRootSelector(state => state.configuration.clientCode);
    const logoutBecauseOfError = useCallback((error: ErrorNormalized) => {
        logError(new Error(`${clientCode} - Authentication Manual Log - status: ${error.status}, statusText: ${error.statusText}, message: ${error.message}`, { cause: error }));
        logout();
    }, [clientCode, logout]);

    return {
        logout,
        logoutBecauseOfError,
        logoutTriggered,
    };
};
