import { InputGroup } from 'react-bootstrap';
import { Field, useFormContext } from 'react-hook-form';
import { useFormError } from 'src/ui/shared/hooks/form-error.hook';
import { HookControlProps } from '../../HookForm';
import { ControlFormGroup } from '../../shared/ControlFormGroup';
import { SelectOptionItems } from './SelectControlItems';

export interface SelectOption<T = string> {
    label: string;
    value: T;
}

export interface HookSelectControlProps extends HookControlProps {
    options: ReadonlyArray<string | number | SelectOption<any>>;
    allowEmpty?: boolean;
    small?: boolean;
    append?: React.ReactNode;
}

export const SelectControl = (
    props: HookSelectControlProps
): React.ReactElement<any, any> | null => {
    const {
        rules,
        options,
        placeholder,
        name,
        errorName,
        disabled,
        allowEmpty,
        tabIndex,
        autoFocus,
        small,
        append,
        errorIndex,
    } = props;
    const { register, control } = useFormContext();

    const controlError = useFormError(errorName || name, errorIndex);
    const controlRef = control._fields[name];

    return (
        <ControlFormGroup {...props}>
            <InputGroup>
                <select
                    disabled={disabled || (controlRef as Field | undefined)?._f.ref.disabled}
                    tabIndex={tabIndex}
                    className={`form-control ${controlError ? 'is-invalid' : ''} ${
                        small ? 'form-control-sm' : ''
                    }`}
                    {...register(name, rules)}
                    autoFocus={autoFocus}
                >
                    <SelectOptionItems
                        items={options}
                        allowEmpty={allowEmpty}
                        placeholder={placeholder}
                    />
                </select>
                {append}
            </InputGroup>
        </ControlFormGroup>
    );
};
