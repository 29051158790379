import type { RootState } from 'src/logic/features/root-state.model';
import { EntityListState } from '../../../models/store-models/entity-list-state.model';
import { EntityBase } from '../../../models/store-models/entity-state.model';
import { listSelector } from '../selectors/entity-list.selector';
import { useRootSelector } from './root-selector.hook';

export const useActiveEntityList = <TEntity extends EntityBase>(
    stateSelector: (state: RootState) => EntityListState<TEntity, any>
) => {
    const requestId = useRootSelector(state => stateSelector(state).activeList.requestId);
    const fetch = useRootSelector(state => stateSelector(state).activeList.fetch);

    const entities = useRootSelector(state => listSelector(stateSelector(state), requestId));
    const listState = useRootSelector(state => stateSelector(state).list[requestId]);
    const pagination = useRootSelector(state => stateSelector(state).activeList.pagination);
    const query = useRootSelector(state => stateSelector(state).activeList.query);

    return {
        entities,
        fetch,
        listState,
        pagination,
        requestId,
        query
    };
};
