import { concat } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { asyncEpicBase } from 'src/logic/helpers/epics/async.epic-helper';
import { routeEpics } from 'src/logic/helpers/epics/entity-route.epic-helper';
import { listEpic } from 'src/logic/helpers/epics/list-route.epic-helper';
import { getRouteMatch } from 'src/logic/helpers/epics/location.epic-helper';
import { redirectEpic } from 'src/logic/helpers/epics/redirect.epic-helper';
import { VerificationPageBaseParams, verificationPathCreator, verificationPaths } from 'src/routes/employer/paths/verification.paths';
import { isActionOf } from 'typesafe-actions';
import { RootEpic } from '../epic.root-index';
import * as actions from './verification.actions';

export const verificationListEpic = listEpic({
    path: verificationPaths.list,
    getParams: (action, _, { queryParams }) => 
        queryParams.verificationListParms(action.payload.location.search),
    getListState: state => state.verifications,
    setActiveActionCreator: actions.verificationListSetActive,
    cancelActionCreator: actions.verificationListAsync.cancel,
    requestActionCreator: actions.verificationListAsync.request,
});

export const verificationListAsyncEpic = asyncEpicBase(
    actions.verificationListAsync,
    (services, ra) => services.api.verifications.list(ra.payload),
    {
        success: result => actions.verificationListAsync.success(result.json),
        failure: (error, ra) => actions.verificationListAsync.failure(error, ra.payload),
    }
);

export const vericationRouteEpics = routeEpics<VerificationPageBaseParams>({
    getMatch: location => getRouteMatch(location.payload.location, verificationPaths.details),
    getListState: state => state.verifications,
    cancelActionCreator: actions.verificationAsync.cancel,
    getId: match => Number(match.params.verificationId),
    setActiveActionCreator: actions.verificationSetActive,
    requestActionCreator: actions.verificationAsync.request,
});

export const verificationAsyncEpic = asyncEpicBase(
    actions.verificationAsync,
    ({ api }, { payload }) => api.verifications.single(payload),
    {
        success: result => actions.verificationAsync.success(result.json),
        failure: (error, ra) => actions.verificationAsync.failure(error, ra.payload),
    },
    {
        cancelFilter: (cancelAction, requestAction) =>
            cancelAction.payload.id === requestAction.payload.id,
    }
);

export const verificationVerifyEpic = asyncEpicBase(
    actions.verificationVerifyAsync,
    ({ api }, { payload }) => api.verifications.verify(payload),
    {
        success: result => actions.verificationVerifyAsync.success(result.json),
        failure: (error, requestAction) => actions.verificationVerifyAsync.failure(error, requestAction.payload),
    }
);

export const verificationDenyEpic = asyncEpicBase(
    actions.verificationDenyAsync,
    ({ api }, { payload }) => api.verifications.deny(payload.id, { feedback: payload.feedback}),
    {
        success: result => actions.verificationDenyAsync.success(result.json),
        failure: (error, requestAction) => actions.verificationDenyAsync.failure(error, requestAction.payload),
    }
);

export const verificationForceNextReloadEpic: RootEpic = (action$, state$) => {
    return action$.pipe(
        filter(
            isActionOf([
                actions.verificationVerifyAsync.success,
                actions.verificationDenyAsync.success,
            ])
        ),
        mergeMap(() =>
            state$.value.router.location.pathname === verificationPaths.list
                ? concat([
                      actions.verificationListClear({ excludeActive: false }),
                      actions.verificationListSetActive(state$.value.verifications.activeList),
                  ])
                : concat([
                    actions.verificationListClear({ excludeActive: false }),
                    actions.verificationListSetActive(state$.value.verifications.activeList),
                ])
                // of(actions.verificationListClear({ excludeActive: false }))
        )
    );
};

export const verificationActionRedirectEpic = redirectEpic({
    when: [actions.verificationVerifyAsync.success, actions.verificationDenyAsync.success],
    from: verificationPaths.details,
    to: verificationPathCreator.listDefault()
});