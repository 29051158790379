import { ErrorNormalized } from "src/models/errors/error.model";
import { createAsyncAction } from "typesafe-actions";
import { OptionValueRequst, EqlOption, OptionQueryRequest } from "./option.models";

export const optionValuesAsync = createAsyncAction(
    'OPTIONS_VALUES:REQUEST',
    'OPTIONS_VALUES:SUCCESS',
    'OPTIONS_VALUES:FAILURE',
    'OPTIONS_VALUES:CANCEL'
)<
    OptionValueRequst,
    [EqlOption[], OptionValueRequst],
    [ErrorNormalized, OptionValueRequst],
    undefined
>();

export const optionQueryAsync = createAsyncAction(
    'OPTIONS_QUERY:REQUEST',
    'OPTIONS_QUERY:SUCCESS',
    'OPTIONS_QUERY:FAILURE',
    'OPTIONS_QUERY:CANCEL'
)<
    OptionQueryRequest,
    [EqlOption[], OptionQueryRequest],
    [ErrorNormalized, OptionQueryRequest],
    undefined
>();