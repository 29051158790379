import { faBan, faPersonRunning, faTicket } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { VerifierSourceEntity } from 'src/typings/api-models.generated';
import { OverlayTriggerWrapper } from 'src/ui/shared/components/bootstrap/overlay/OverlayTriggerWrapper';
import { Icon } from 'src/ui/shared/components/icon/Icon';

interface Props {
    item: VerifierSourceEntity | undefined;
    onClick?: (item: VerifierSourceEntity) => void;
}

type ValidType = 'event' | 'activity' | 'unknown';

export const SourceItemName = ({ item, onClick }: Props): React.ReactElement => {
    const type: ValidType = useMemo(() => {
        const lowercase = item?.type.toLowerCase();
        switch (lowercase) {
            case 'event':
            case 'activity':
                return lowercase;
            default:
                return 'unknown';
        }
    }, [item?.type]);

    const icon = useMemo(() => {
        switch (type) {
            case 'event':
                return faTicket;
            case 'activity':
                return faPersonRunning;
            default:
                return faBan;
        }
    }, [type]);

    const title = useMemo(() => {
        return item?.title || 'No title';
    }, [item?.title]);

    if (type === 'unknown') {
        return (
            <OverlayTriggerWrapper
                placement="bottom"
                id="verification-source-item"
                tooltip={<>Unable to retrieve information about this item.</>}
            >
                <span>
                    <Icon icon={icon}>Unknown source</Icon>
                </span>
            </OverlayTriggerWrapper>
        );
    }

    if (!onClick) {
        return <Icon icon={icon}>{title}</Icon>;
    }

    return (
        <a href="javascript:;" onClick={item ? () => onClick(item) : undefined}>
            <Icon icon={icon}>{title}</Icon>
        </a>
    );
};
