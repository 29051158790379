import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { Experience } from "src/typings/api-models.generated";
import { DateDisplay } from "src/ui/shared/components/details/DateDisplay";
import { Icon } from "src/ui/shared/components/icon/Icon";

interface Props {
    experience: Experience;
} 

export const ExperienceStartEndDate = ({ experience }: Props): React.ReactElement | null => {

    if (!experience.startDate) {
        return null;
    }

    return (
        <Icon icon={faCalendar}>
            <DateDisplay
                showTime
                date={experience.startDate}
                endDate={experience.endDate}
            />
        </Icon>
    )
}