import { addDays, isAfter, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { Verification } from 'src/typings/api-models.generated';
import { OverlayTriggerWrapper } from 'src/ui/shared/components/bootstrap/overlay/OverlayTriggerWrapper';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';

interface Props {
    verification: Verification;
}

export const OverdueStatusDisplay = ({ verification }: Props): React.ReactElement | null => {
    const config = useApiConfiguration();
    const overdueDays = useMemo(() => config.value?.settings?.verificationOverdueDays, []);

    const isOverdue = useMemo(() => {
        if (!overdueDays) {
            return false;
        }

        const added = parseISO(verification.addedDate);
        const overdueDate = addDays(added, overdueDays);
        const now = new Date();

        return isAfter(now, overdueDate);

        return;
    }, [overdueDays, verification.addedDate]);

    if (!isOverdue) {
        return null;
    }

    if (verification.status !== 'pending') {
        return null;
    }

    return (
        <OverlayTriggerWrapper
            id={`overdue-${verification.id}`}
            tooltip={<>This verification was requested more than {overdueDays} days ago</>}
        >
            <a href="javascript:;">
                <Badge variant="outline-warning" className="mr-2">
                    Overdue
                </Badge>
            </a>
        </OverlayTriggerWrapper>
    );
};
