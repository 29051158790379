import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { authenticationHasRedirected } from 'src/logic/features/authentication/authentication.actions';
import { eventPathCreator } from 'src/routes/employer/paths/event.paths';
import { jobPathCreator } from 'src/routes/employer/paths/job.paths';
import { organisationPaths } from 'src/routes/employer/paths/organisation.paths';
import { identityPaths } from 'src/routes/identity/identity.paths';
import { Loader } from 'src/ui/shared/components/loader/Loader';
import { ThinPageContent } from 'src/ui/shared/components/page-components/ThinPageContent';
import { useApiConfiguration } from 'src/ui/shared/hooks/api-configuration.hook';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

interface Props {
    to: string;
}

const InitRedirect = ({ to }: Props): React.ReactElement => {
    const [done, setDone] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (done) {
            return;
        }
        dispatch(authenticationHasRedirected());
        setDone(true);
    }, [dispatch, done]);

    return <Redirect to={to} />;
};

const CustomRedirect = (): React.ReactElement => {
    const apiConfig = useApiConfiguration();
    const hasInitRedirected = useRootSelector(
        state => state.authentication.initCompleteHasRedirected
    );
    const initRedirectTo = useRootSelector(state => state.authentication.initCompleteRedirectTo);
    const activeIdentity = useRootSelector(state => state.authentication.activeIdentity);
    const identities = useRootSelector(state => state.authentication.identities);

    const jobDisabled = apiConfig.value?.settings?.disableJobs !== true;
    const eventDisabled = apiConfig.value?.settings?.disableEvents !== true;

    // have to cater for previous versions where these settings did not exist
    // if the setting doesn't exist, it won't be equal to 'true', and therefore will
    // redirect to jobs. Which is what we want
    const defaultRedirect = jobDisabled
        ? jobPathCreator.listDefault()
        : eventDisabled
        ? eventPathCreator.listDefault()
        : // this is the same path for individuals and orgs
          // bit hacky to not check this
          organisationPaths.details;

    const initRedirect = initRedirectTo === '/' ? defaultRedirect : initRedirectTo;

    if (!activeIdentity && identities.length > 1) {
        return <Redirect to={identityPaths.list} />;
    }

    if (hasInitRedirected) {
        return <Redirect to={defaultRedirect} />;
    }

    return <InitRedirect to={initRedirect} />;
};

export const AuthLandingPage = (): React.ReactElement => {
    const apiConfig = useApiConfiguration();

    return (
        <ThinPageContent>
            <Loader state={apiConfig.fetch}>
                <CustomRedirect />
            </Loader>
        </ThinPageContent>
    );
};
