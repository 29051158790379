import * as sentry from '@sentry/react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
// this is a polyfill that should allow fetch requests on iOS safari
import 'whatwg-fetch';
import { executeConfigurationInit } from './clients-internal/configuration.init';
import {
    REACT_APP_COMMIT_REF,
    REACT_APP_SENTRY_DSN,
} from './clients-internal/environment.constants';
import { client, rootElement } from './index.helper';
import { createSilentUserManager } from './logic/features/authentication/services/authentication.service';
import './styles/stub.scss';
import { App } from './ui/App';

if (window.top === window.self) {
    executeConfigurationInit(client);

    // all environments (clients) use the same error endpoint
    if (process.env.NODE_ENV !== 'development') {
        sentry.init({
            dsn: REACT_APP_SENTRY_DSN,
            release: REACT_APP_COMMIT_REF,
            normalizeDepth: 10,
            environment: client,
        });
    }

    // ToDo: Re-add strict mode when this is fixed: https://github.com/JedWatson/react-select/pull/3928
    // ToDo update: I think there are more things causing issues with Strict mode (I'm pretty sure redux-observable has issues with this too now)
    ReactDOM.render(
        // <React.StrictMode>
        <App />,
        // </React.StrictMode>,
        rootElement
    );
} else {
    // This is amazing!
    // This is a beautifully simple way of avoiding all of the complexity
    // of having another physical silent_renew.html start point.
    // NEW NOTE: I manually call sign in slient, but monitor session is not enabled
    void createSilentUserManager().signinSilentCallback();
}
