import { push } from 'connected-react-router';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select, { ActionMeta, InputActionMeta, MultiValue } from 'react-select';
import { optionQueryAsync, optionValuesAsync } from 'src/logic/features/options/option.actions';
import { OptionType } from 'src/logic/features/options/option.models';
import { verificationPathCreator } from 'src/routes/employer/paths/verification.paths';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { useDebouncedCallback } from 'use-debounce';

interface InnerOption {
    value: number;
    label: string;
    invalid?: boolean;
}

interface Props {
    optionType: OptionType;
    placeholder?: string;
    autoFocus?: boolean;
    disabled?: boolean;
}

type EqualityFn<T> = (a: T, b: T) => boolean;
const eqCompare: EqualityFn<InnerOption[]> = (a, b) => 
    a.length === b.length && a.every((value, index) => value.value === b[index].value);

export const SearchOptionSelect = ({ optionType, placeholder, autoFocus, disabled  }: Props): React.ReactElement => {
    const [hasDispatchedValues, setHasDispatchedValues] = useState(false);
    const dispatch = useDispatch();
    const locationQuery = useRootSelector(state => state.router.location.query);
    const valuesState = useRootSelector(state => state.options[optionType].values.state);
    const queryState = useRootSelector(state => state.options[optionType].query.state);

    const allOptionMap = useRootSelector(state => {
        return state.options[optionType].options;
    });

    const values = useMemo(() => {
        if (!locationQuery[optionType]) {
            return [];
        }

        return locationQuery[optionType]
            .split(',')
            .map(x => Number(x))
            .filter(x => !isNaN(x));
    }, [locationQuery, optionType])

    useEffect(() => {

        if (hasDispatchedValues) {
            return;
        }

        if (!values) {
            return;
        }

        if (values.some(x => !allOptionMap[x])) {
            dispatch(optionValuesAsync.request({ optionType, values: values}));
            setHasDispatchedValues(true);
        }

    }, [allOptionMap, dispatch, hasDispatchedValues, optionType, values]);

    const isLoading = useMemo(() => {
        return valuesState.loading || queryState.loading;
    }, [valuesState.loading, queryState.loading]);

    const options = useRootSelector(state => {
        const values = state.options[optionType].query.values;
        const toReturn: InnerOption[] = values
            .map(x => state.options[optionType].options[x])
            .filter(x => !!x)
            .map(x => ({
                value: x!.value,
                label: x!.name,
            }));
        return toReturn;
    }, eqCompare as EqualityFn<unknown>);

    const value = useMemo(() => {
        const toReturn: InnerOption[] = values
            .map(x => {
                const existing = allOptionMap[x];
                
                return {
                    label: existing ? existing.name : x.toString(),
                    value: x,
                    invalid: !existing
                }
            });

        return toReturn;
    }, [allOptionMap, values]);

    const onChange = (newValue: MultiValue<InnerOption>, actionMeta: ActionMeta<InnerOption>) => {
        const path = verificationPathCreator.list({ ...locationQuery, [optionType]: newValue.map(x => x.value) || undefined });
        dispatch(push(path));
    }

    const onInputChange = useDebouncedCallback((newValue: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change') {
            dispatch(optionQueryAsync.request({ query: newValue, optionType }));
        }
    }, 600);

    const onMenuOpen = (() => {
        dispatch(optionQueryAsync.request({ query: '', optionType }));
    });

    return (
        <Select<InnerOption, true>
            isMulti
            isLoading={isLoading}
            // components={overrideComponents}
            closeMenuOnSelect={false}
            onChange={onChange}
            onInputChange={onInputChange}
            options={options}
            onMenuOpen={onMenuOpen}
            value={value}
            placeholder={placeholder}
            openMenuOnFocus
            autoFocus={autoFocus}
            isDisabled={disabled}
            styles={{
                container: css => ({ ...css, flex: '1 1 auto', alignSelf: 'stretch' }),
                valueContainer: css => ({ ...css, padding: '0 4px' }),
                indicatorSeparator: css => ({ ...css, margin: '0' }),
                input: css => ({ ...css, padding: '0' }),
                control: css => ({ ...css, minHeight: '29px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }),
                multiValue: css => ({ ...css, margin: '1px', fontSize: '0.85rem' }),
                placeholder: css => ({ ...css, fontSize: '0.85rem' }),
                menu: css => ({ ...css, fontSize: '0.85rem', minWidth: '200px' }),

                // might not be necessary (cause I might change all the indicators)
                dropdownIndicator: css => ({ ...css, padding: '0 4px' }),
                clearIndicator: css => ({ ...css, padding: '0 4px' }),
            }}
            classNames={{
                multiValue: props => props.data.invalid ? 'bg-danger-light border border-danger' : '',
            }}
            // menuIsOpen
        />
    )
}