import { ExperienceSkillNormalized } from 'src/models/verifications/verification.models';
import { OverlayTriggerWrapper } from 'src/ui/shared/components/bootstrap/overlay/OverlayTriggerWrapper';
import { ExperienceSkillRankDisplay } from './ExperienceSkillRankDisplay';

interface Props {
    normalized: ExperienceSkillNormalized;
}

export const VerificationSkillDisplay = ({
    normalized: { skill, experienceSkill },
}: Props): React.ReactElement => {
    return (
        <>
            <OverlayTriggerWrapper
                id={`tooltip-skill-${skill.id}`}
                trigger="focus"
                tooltip={
                    <div>
                        {skill.description || (
                            <span className="font-italic">No description provided</span>
                        )}
                    </div>
                }
            >
                <a href="javascript:;" className="text-black">
                    <span className="mr-2">{skill.name}</span>
                </a>
            </OverlayTriggerWrapper>
            <span className="mr-2">
                <ExperienceSkillRankDisplay experienceSkill={experienceSkill} />
            </span>
        </>
    );
};
