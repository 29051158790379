import { useMemo } from 'react';
import type { RootState } from 'src/logic/features/root-state.model';
import { LoaderState } from '../../../models/errors/error.model';
import { EntityListState } from '../../../models/store-models/entity-list-state.model';
import { EntityBase } from '../../../models/store-models/entity-state.model';
import { useRootSelector } from './root-selector.hook';

const useDefaultLoaderState = (state: LoaderState | undefined) => {
    return useMemo(() => (state ? state : { loading: false }), [state]);
};

export const useActiveEntity = <TEntity extends EntityBase>(
    stateSelector: (state: RootState) => EntityListState<TEntity, any>
) => {
    const id = useRootSelector(state => stateSelector(state).activeId);
    const entity = useRootSelector(state => {
        if (!id) {
            return undefined;
        }

        return stateSelector(state).items[id];
    });

    const singleState = useRootSelector(state => {
        if (!id) {
            return undefined;
        }
        const entityState = stateSelector(state);

        return entityState.single[id];
    });

    const fetch = useDefaultLoaderState(singleState?.fetch);
    const update = useDefaultLoaderState(singleState?.update);

    return {
        id,
        entity,
        fetch,
        update,
        deleted: !!singleState?.deleted,
    };
};
