import { ActionsObservable, combineEpics, Epic, StateObservable } from 'redux-observable';
import { concat, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorNormalized } from 'src/models/errors/error.model';
import { logError } from 'src/ui/features/sentry/helpers/sentry.helper';
import { RootAction } from './action.root-index';
import * as analytics from './analytics/analytics.epics';
import * as categories from './api-configuration/api-configuration.epics';
import * as attachments from './attachments/attachment.epics';
import * as authentication from './authentication/epics';
import * as careerhub from './careerhub/epics/careerhub-redirect.epics';
import * as central from './central/epics';
import * as contacts from './contacts/epics';
import * as content from './content/content.epics';
import * as emailClaims from './email-claims/email-claims.epics';
import * as endorsements from './endorsements/endorsement.epics';
import * as entityNotes from './entity-notes/entity-note.epics';
import * as eventBookings from './event-bookings/event-booking.epics';
import * as eventUnions from './event-unions/event-union.epics';
import * as formSubmissions from './form-submissions/epics';
import * as individual from './individuals/individual.epics';
import { masterEpicError } from './initialization/initialization.actions';
import * as init from './initialization/initialization.epics';
import * as jobs from './jobs/epics';
import * as organisation from './organisations/organisation.epics';
import * as register from './register/epics';
import { Services } from './service.root-index';
import * as settings from './settings/setting.epics';
import * as skills from './skills/skill.epics';
import * as uploads from './uploads/upload.epics';
import * as verifications from './verifications/verifications.epics';
import * as options from './options/option.epics';
import * as workGroups from './work-groups/work-group.epics';
import type { RootState } from 'src/logic/features/root-state.model';

export type RootEpic = Epic<RootAction, RootAction, RootState, Services>;

const rootEpic = (
    actions$: ActionsObservable<RootAction>,
    state$: StateObservable<RootState>,
    services: Services
) =>
    combineEpics(
        // order matters
        ...Object.values(init),
        ...Object.values(authentication),
        ...Object.values(jobs),
        ...Object.values(eventUnions),
        ...Object.values(eventBookings),
        ...Object.values(organisation),
        ...Object.values(individual),
        ...Object.values(contacts),
        ...Object.values(register),
        ...Object.values(settings),
        ...Object.values(categories),
        ...Object.values(content),
        ...Object.values(attachments),
        ...Object.values(formSubmissions),
        ...Object.values(workGroups),
        ...Object.values(central),
        ...Object.values(analytics),
        ...Object.values(entityNotes),
        ...Object.values(careerhub),
        ...Object.values(uploads),
        ...Object.values(skills),
        ...Object.values(endorsements),
        ...Object.values(verifications),
        ...Object.values(options),
        ...Object.values(emailClaims)
    )(actions$, state$, services).pipe(
        catchError((error, source) => {
            // this restarts the root epic can have some unintended consequences, so be careful.
            logError(error);
            console.error('error in master epic stream', error);

            const normalized: ErrorNormalized = {
                status: -1,
                statusText: 'Epic master stream error',
                message: 'Unexpected error in master stream.',
            };

            // this works, but it's probably not correct
            // given that the type safety has to be forced.
            // a master epic error should be extremely uncommon
            // and will result in the entire app switching to an error screen
            return concat([masterEpicError(normalized), source]) as Observable<RootAction>;
        })
    );

export default rootEpic;
