import { faFile, faFileAudio, faFileImage, faFileVideo } from '@fortawesome/free-regular-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { ExperienceResource } from 'src/typings/api-models.generated';
import { Icon } from 'src/ui/shared/components/icon/Icon';

interface Props {
    resource: ExperienceResource;
}

export const VerificationResourceDisplay = ({ resource }: Props): React.ReactElement => {
    const icon = useMemo(() => {
        switch (resource.type) {
            case 'audio':
                return faFileAudio;
            case 'download':
                return faFile;
            case 'image':
                return faFileImage;
            case 'video':
                return faFileVideo;
            case 'link':
                return faArrowUpRightFromSquare;
            case 'object':
            case 'embed':
            case 'iFrame':
            default:
                return faArrowUpRightFromSquare;
        }
    }, [resource.type]);

    return (
        <span>
            <Icon icon={icon} iconClassName="fa-fw">
                <a href={resource.url} rel="noreferrer" target="_blank">
                    {resource.name}
                </a>
            </Icon>
        </span>
    );
};
