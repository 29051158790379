import { getClientConfig } from "src/clients-internal/configuration.init";
import { apiStringify } from "src/logic/helpers/api-query-string.helper";
import { fetchHelper } from "src/logic/helpers/fetch.helper";
import { ObsApiExpected } from "src/models/api/service.model";
import { EqlOption, OptionQueryRequest, OptionType, OptionValueRequst } from "./option.models";

const basePath = () => `${getClientConfig().careerHubBasePath}/services/employers/v2/options`;

const paths: { [key in OptionType]: string } = {
    events: 'verifier/events',
    activities: 'verifier/activities'
}

export function query(request: OptionQueryRequest): ObsApiExpected<EqlOption[]> {
    const search = apiStringify({ 
        q: request.query,
        take: request.take
    });
    
    return fetchHelper.get(`${basePath()}/${paths[request.optionType]}${search}`);
}

export function values(request: OptionValueRequst): ObsApiExpected<EqlOption[]> {
    const search = apiStringify({ 
        values: request.values,
        take: request.values.length
    });
    
    return fetchHelper.get(`${basePath()}/${paths[request.optionType]}${search}`);
}