import { faUser } from '@fortawesome/free-regular-svg-icons';
import { VerifierSourceStudent } from 'src/typings/api-models.generated';
import { OverlayTriggerWrapper } from 'src/ui/shared/components/bootstrap/overlay/OverlayTriggerWrapper';
import { Icon } from 'src/ui/shared/components/icon/Icon';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

interface Props {
    student: VerifierSourceStudent | undefined;
}

export const StudentName = ({ student }: Props): React.ReactElement => {
    const { jobseeker } = useRootSelector(state => state.strings.careerHubStrings);

    if (!student) {
        return (
            <OverlayTriggerWrapper
                id="verification-unknown-student"
                placement="bottom"
                tooltip={<div>This {jobseeker} has not given permission to share their data.</div>}
            >
                <span>
                    <Icon icon={faUser}>Unknown {jobseeker}</Icon>
                </span>
            </OverlayTriggerWrapper>
        );
    }

    return (
        <Icon icon={faUser}>
            {student.firstName} {student.lastName}
        </Icon>
    );
};
