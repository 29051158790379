import { ListGroupItemActions } from './ListGroupItemActions';
import { ListGroupItemTitle } from './ListGroupItemTitle';

interface Props {
    children: React.ReactNode;
    className?: string;
}

export const ListGroupItemHeader = ({ children, className }: Props): React.ReactElement => {
    return <div className={`d-flex align-items-end mb-2 ${className || ''}`}>{children}</div>;
};

ListGroupItemHeader.Title = ListGroupItemTitle;
ListGroupItemHeader.Actions = ListGroupItemActions;
