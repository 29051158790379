import { OverlayTrigger, OverlayTriggerProps, Tooltip } from 'react-bootstrap';

interface Props {
    id: string;
    tooltip: React.ReactNode;
    children: React.ReactElement;
    trigger?: OverlayTriggerProps['trigger'];
    placement?: OverlayTriggerProps['placement'];
}

export const OverlayTriggerWrapper = ({
    id,
    tooltip,
    children,
    trigger,
    placement,
}: Props): React.ReactElement => (
    <OverlayTrigger
        trigger={trigger}
        placement={placement}
        transition={false}
        overlay={props => (
            <Tooltip id={id} {...props}>
                <>{tooltip}</>
            </Tooltip>
        )}
    >
        {children}
    </OverlayTrigger>
);
