import { ListPaginationState } from '../../../../models/store-models/entity-list-state.model';
import { ListPagination } from '../bootstrap/pagination/ListPagination';

interface Props {
    pagination: ListPaginationState;
    children?: React.ReactNode;
}

export const ListPaginationWrapper = ({
    children,
    pagination,
}: Props): React.ReactElement<any, any> | null => {
    return (
        <div className="d-md-flex">
            {children}
            {pagination.pageTotal > 1 && (
                <div className="ml-sm-auto mb-3">
                    <ListPagination pagination={pagination} />
                </div>
            )}
        </div>
    );
};
