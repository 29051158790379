import { Route, Switch } from 'react-router-dom';
import { VerificationDetailsPage } from 'src/ui/features/verifications/pages/VerificationDetailsPage';
import { VerificationListPage } from 'src/ui/features/verifications/pages/VerificationListPage';
import { verificationPaths } from './paths/verification.paths';

export const VerificationRouter = () => (
    <Switch>
        <Route path={verificationPaths.details} component={VerificationDetailsPage} />
        <Route path={verificationPaths.list} component={VerificationListPage} />
    </Switch>
);
