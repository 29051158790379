import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import compact from 'lodash/compact';
import { match as IMatch } from 'react-router';
import { combineEpics } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { RootEpic } from 'src/logic/features/epic.root-index';
import type { RootState } from 'src/logic/features/root-state.model';
import { PayloadActionCreator, isActionOf, isOfType } from 'typesafe-actions';
import { EntityRequest, EntityRequestOrUndefined } from '../../../models/api/request.model';
import { EntityListState } from '../../../models/store-models/entity-list-state.model';
import { delayTypeUntilAuthenticated } from './app-init.epic-helper';

interface SetActiveActionCreator {
    setActiveActionCreator: PayloadActionCreator<any, EntityRequestOrUndefined>;
}

interface GetListState {
    getListState: (state: RootState) => EntityListState<any, any>;
}

interface EntityRouteRequestOptions extends SetActiveActionCreator, GetListState {
    requestActionCreator: PayloadActionCreator<any, any>;
}

interface EntityRouteCancelOptions<TParams extends { [K in keyof TParams]?: string | undefined }>
    extends GetListState {
    getMatch: (locationChangeAction: LocationChangeAction) => IMatch<TParams> | null;
    cancelActionCreator: PayloadActionCreator<any, EntityRequest>;
}

interface EntityRouteOptions<TParams extends { [K in keyof TParams]?: string | undefined }>
    extends EntityRouteCancelOptions<TParams>,
        SetActiveActionCreator {
    getId: (match: IMatch<TParams>) => number;
}

type AllTheInterfaces<TParams extends { [K in keyof TParams]?: string | undefined }> =
    EntityRouteRequestOptions & EntityRouteCancelOptions<TParams> & EntityRouteOptions<TParams>;

const entityRouteEpic: <TParams extends { [K in keyof TParams]?: string | undefined }>(
    options: EntityRouteOptions<TParams>
) => RootEpic = options => {
    return (action$, state$) => {
        return delayTypeUntilAuthenticated(action$, state$, LOCATION_CHANGE).pipe(
            mergeMap(i => {
                const match = options.getMatch(i);
                if (!match) {
                    return EMPTY;
                }

                const id = options.getId(match);
                const previousId = options.getListState(state$.value).activeId;

                const cancelAction =
                    previousId &&
                    previousId !== id &&
                    options.getListState(state$.value).single[previousId]?.fetch.loading
                        ? options.cancelActionCreator({ id: previousId })
                        : undefined;

                const setActiveAction =
                    previousId !== id ? options.setActiveActionCreator({ id }) : undefined;

                return compact([cancelAction, setActiveAction]);
            })
        );
    };
};

const entityRouteCancelEpic: <TParams extends { [K in keyof TParams]?: string | undefined }>(
    options: EntityRouteCancelOptions<TParams>
) => RootEpic = options => {
    return (action$, state$) => {
        return action$.pipe(
            filter(isOfType(LOCATION_CHANGE)),
            filter(i => !options.getMatch(i)),
            mergeMap(i => {
                const activeId = options.getListState(state$.value).activeId;
                if (!activeId) {
                    return EMPTY;
                }
                const fetching = options.getListState(state$.value).single[activeId]?.fetch.loading;

                return fetching ? of(options.cancelActionCreator({ id: activeId })) : EMPTY;
            })
        );
    };
};

const entityRouteRequestEpic: (options: EntityRouteRequestOptions) => RootEpic = options => {
    return (action$, state$) => {
        return action$.pipe(
            filter(isActionOf(options.setActiveActionCreator)),
            filter(
                action =>
                    !!action.payload.id &&
                    !options.getListState(state$.value).single[action.payload.id]
            ),
            mergeMap(action => of(options.requestActionCreator(action.payload)))
        );
    };
};

export const routeEpics = <TParams extends { [K in keyof TParams]?: string | undefined }>(
    options: AllTheInterfaces<TParams>
): RootEpic => {
    return combineEpics(
        entityRouteRequestEpic(options),
        entityRouteEpic(options),
        entityRouteCancelEpic(options)
    );
};
