import keyBy from 'lodash/keyBy';
import { LoaderState } from "src/models/errors/error.model";
import { createReducer } from "typesafe-actions";
import * as actions from './option.actions';
import { EqlOption, OptionType } from "./option.models";

interface OptionTypeState {
    values: {
        state: LoaderState;
    },
    query: {
        state: LoaderState;
        values: number[];
        take?: number;
        hasMore: boolean;
    },
    options: {
        [value: number]: EqlOption | undefined
    };
}

export type OptionState = {
    [key in OptionType]: OptionTypeState;
}

const initialTypeState: OptionTypeState = {
    values: { state: { loading: false }},
    query: {
        state: { loading: false },
        values: [],
        hasMore: false
    },
    options: {}
}

const initialState: OptionState = {
    events: { ...initialTypeState },
    activities: { ...initialTypeState }
}

const optionReducer = createReducer(initialState)
    .handleAction(actions.optionQueryAsync.request, (state, action) => ({
        ...state,
        [action.payload.optionType]: {
            ...state[action.payload.optionType],
            query: {
                ...state[action.payload.optionType].query,
                state: { loading: true }
            }
        }
    }))
    .handleAction(actions.optionQueryAsync.success, (state, action) => ({
        ...state,
        [action.meta.optionType]: {
            ...state[action.meta.optionType],
            query: {
                ...state[action.meta.optionType].query,
                state: { loading: false },
                values: action.payload.map(x => x.value)
            },
            options: {
                ...state[action.meta.optionType].options,
                ...keyBy(action.payload, x => x.value)
            }
        }
    }))
    .handleAction(actions.optionQueryAsync.failure, (state, action) => ({
        ...state,
        [action.meta.optionType]: {
            ...state[action.meta.optionType],
            query: {
                ...state[action.meta.optionType].query,
                state: { loading: false, error: action.payload }
            },
        }
    }))

    .handleAction(actions.optionValuesAsync.request, (state, action) => ({
        ...state,
        [action.payload.optionType]: {
            ...state[action.payload.optionType],
            values: {
                state: { loading: true }
            }
        }
    }))
    .handleAction(actions.optionValuesAsync.success, (state, action) => ({
        ...state,
        [action.meta.optionType]: {
            ...state[action.meta.optionType],
            values: {
                state: { loading: false },
            },
            options: {
                ...state[action.meta.optionType].options,
                ...keyBy(action.payload, x => x.value)
            }
        }
    }))
    .handleAction(actions.optionValuesAsync.failure, (state, action) => ({
        ...state,
        [action.meta.optionType]: {
            ...state[action.meta.optionType],
            values: {
                state: { loading: false, error: action.payload }
            },
        }
    }));

export default optionReducer;