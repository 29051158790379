import React from 'react';
import { ReflectionSection } from 'src/typings/api-models.generated';
import { stringHelper } from 'src/ui/shared/helpers/string.helper';

interface Props {
    sections: ReflectionSection[];
}

const { toTitleCase } = stringHelper;

export const ReflectionSectionListDisplay = ({ sections }: Props): React.ReactElement => {

    if (sections.length === 1) {
        return (
            <div className='mb-2'>
                <span>{sections[0].text}</span>
            </div>
        )
    }

    return (
        <ul className='mb-2'>
            {sections.map((section, i) => (
                <li key={section.name || i}>
                    <span className="font-weight-bold">{toTitleCase(section.name || (i + 1).toString())}: </span>
                    <span key={i}>{section.text}</span>
                </li>
            ))}
        </ul>
    );
};
