import { Modal } from "react-bootstrap"
import noop from 'lodash/noop';
import { useRootSelector } from "src/ui/shared/hooks/root-selector.hook";
import { HookForm } from "src/ui/shared/components/forms/HookForm";
import { createMaxLengthRule } from "src/ui/shared/helpers/validation.helper";
import { useCallback } from "react";
import { useFormDefault } from "src/ui/shared/hooks/form-default.hook";
import { useDispatch } from "react-redux";
import { verificationDenyAsync } from "src/logic/features/verifications/verification.actions";

interface Props {
    verificationId: number;
    onCancel: () => void;
}

interface FormData {
    feedback: string;
}

export const VerificationDenyFormModal = ({ verificationId, onCancel }: Props): React.ReactElement => {

    const dispatch = useDispatch();
    const update = useRootSelector(state => state.verifications.single[verificationId]?.update);

    const onSubmit = useCallback(({ feedback }: FormData) => {
        dispatch(verificationDenyAsync.request({ id: verificationId, feedback }))
    }, [dispatch, verificationId]);

    const formMethods = useFormDefault<FormData>({
        error: update?.error,
        defaultValues: {}
    });

    return (
        <Modal show={true} onHide={noop} animation={false}>
            <HookForm {...formMethods} onSubmit={onSubmit}>
                <HookForm.ErrorAlert error={update?.error} />
                <Modal.Header>
                    <Modal.Title>Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <HookForm.Textarea
                        disabled={update?.loading}
                        name="feedback"
                        label="Feedback"
                        minRows={3}
                        rules={{ required: true, maxLength: createMaxLengthRule(4000) }}
                    />

                    <HookForm.Buttons
                        submitText="Submit"
                        cancelText="Cancel"
                        onCancel={onCancel}
                        showSpinner={update?.loading}
                    />
                </Modal.Body>
            </HookForm>
        </Modal>
    )
}