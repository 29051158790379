import { useCallback } from 'react';
import { Pagination } from 'react-bootstrap';
import { VERIFICATION_STATUS_OPTIONS } from 'src/models/verifications/verification.models';
import { VerificationStatus } from 'src/typings/api-models.generated';

interface Props {
    onClick: (status: Capitalize<VerificationStatus>) => void;
    active: Capitalize<VerificationStatus>[];
}

export const VerificationStatusFilter = ({ onClick, active }: Props): React.ReactElement => {
    const isActive = useCallback(
        (x: Capitalize<VerificationStatus>) => active.includes(x),
        [active]
    );

    return (
        <Pagination size="sm" className="pagination-button-group flex-wrap">
            {VERIFICATION_STATUS_OPTIONS.map(x => (
                <Pagination.Item
                    activeLabel="active label"
                    key={x}
                    active={isActive(x)}
                    onClick={() => onClick(x)}
                >
                    {x}
                </Pagination.Item>
            ))}
        </Pagination>
    );
};
