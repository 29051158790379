import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { OptionType } from 'src/logic/features/options/option.models';
import { verificationPathCreator } from 'src/routes/employer/paths/verification.paths';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';
import { SearchOptionSelect } from '../options/SeachOptionSelect';

const { list } = verificationPathCreator;

export const VerificationSourceItemFilter = (): React.ReactElement => {
    const [selected, setSelected] = useState<OptionType | undefined>(undefined);
    const locationQuery = useRootSelector(state => state.router.location.query);
    const dispatch = useDispatch();

    const onClick = (option: OptionType | undefined) => {
        if (selected === option) {
            return;
        }

        if (option === undefined) {
            const path = list({
                ...locationQuery,
                events: undefined,
                activities: undefined,
            });
            dispatch(push(path));
        } else if (option === 'events') {
            const path = list({
                ...locationQuery,
                events: 'all',
                activities: undefined,
            });
            dispatch(push(path));
        } else if (option === 'activities') {
            const path = list({
                ...locationQuery,
                events: undefined,
                activities: 'all',
            });
            dispatch(push(path));
        }

        setSelected(option);
    };

    useEffect(() => {
        console.log('l', locationQuery);
        if (locationQuery['events']) {
            setSelected('events');
        } else if (locationQuery['activities']) {
            setSelected('activities');
        }
    }, [locationQuery]);

    return (
        <InputGroup size="sm">
            <InputGroup.Prepend>
                <Button
                    variant="outline-primary"
                    onClick={() => onClick(undefined)}
                    active={!selected}
                >
                    All
                </Button>
                <Button
                    variant="outline-primary"
                    onClick={() => onClick('events')}
                    active={selected === 'events'}
                >
                    Events
                </Button>
                <Button
                    variant="outline-primary"
                    onClick={() => onClick('activities')}
                    active={selected === 'activities'}
                >
                    Activities
                </Button>
            </InputGroup.Prepend>
            <SearchOptionSelect
                optionType={selected || 'events'}
                placeholder={'Filter...'}
                disabled={!selected}
            />
        </InputGroup>
    );
};
