import { verificationPathCreator } from 'src/routes/employer/paths/verification.paths';
import { BreadcrumbLink } from 'src/ui/shared/components/breadcrumbs/BreadcrumbLink';
import { Upper } from 'src/ui/shared/components/text/Upper';
import { useStrings } from 'src/ui/shared/hooks/strings.hook';

export const VerificationListBreadcrumb = (): React.ReactElement<any, any> | null => {
    const {
        nav: {
            application: { verifications },
        },
    } = useStrings();
    return (
        <BreadcrumbLink to={verificationPathCreator.listDefault()}>
            <Upper>{verifications}</Upper>
        </BreadcrumbLink>
    );
};
