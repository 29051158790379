import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { CombinedState, combineReducers, Reducer } from 'redux';
import analytics from './analytics/analytics.reducer';
import apiConfiguration from './api-configuration/api-configuration.reducer';
import attachments from './attachments/attachment.reducer';
import authentication from './authentication/authentication.reducer';
import central from './central/central.reducer';
import configuration from './configuration/configuration.reducer';
import contacts from './contacts/contact.reducer';
import content from './content/content.reducer';
import emailClaims from './email-claims/email-claims.reducer';
import endorsements from './endorsements/endorsement.reducer';
import entityNotes from './entity-notes/entity-note.reducer';
import { eventBookingReducer } from './event-bookings/event-booking.reducer';
import eventUnions from './event-unions/event-union.reducer';
import formSubmissions from './form-submissions/form-submission.reducer';
import individual from './individuals/individual.reducer';
import init from './initialization/initialization.reducer';
import jobs from './jobs/job.reducer';
import options from './options/option.reducer';
import organisation from './organisations/organisation.reducer';
import register from './register/register.reducer';
import type { RootState } from './root-state.model';
import session from './sessions/session.reducer';
import settings from './settings/setting.reducer';
import skills from './skills/skill.reducer';
import strings from './strings/string.reducer';
import uploads from './uploads/upload.reducer';
import verifications from './verifications/verification.reducer';
import workGroups from './work-groups/work-group.reducer';

export const routerHistory = createBrowserHistory();
const routerReducer = connectRouter(routerHistory);

type RootReducer = Reducer<CombinedState<RootState>>;

const rootReducer: RootReducer = combineReducers({
    router: routerReducer,
    init,
    authentication,
    jobs,
    organisation,
    individual,
    contacts,
    register,
    eventUnions,
    eventBookings: eventBookingReducer,
    settings,
    apiConfiguration,
    content,
    attachments,
    formSubmissions,
    session,
    central,
    strings,
    emailClaims,
    workGroups,
    entityNotes,
    configuration,
    uploads,
    skills,
    endorsements,
    verifications,
    options,
    analytics,
});

export default rootReducer;
