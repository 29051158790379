import { VerificationNormalized } from "src/models/verifications/verification.models";
import { ReflectionDisplay } from "./ReflectionDisplay";
import { VerificationDetailsSectionTitle } from "../details/VerificationDetailsSectionTitle";

interface Props {
    normalized: VerificationNormalized;
}

export const VerificationExperienceReflectionDisplay = ({ normalized }: Props): React.ReactElement | null => {

    if (normalized.experienceReflections.length === 0) {
        return null;
    }

    return (
        <>
            <VerificationDetailsSectionTitle>Reflections on experience</VerificationDetailsSectionTitle>
            <div className="pl-2 border-left">
                {normalized.experienceReflections.map(x => (
                    <ReflectionDisplay key={x.id}  reflection={x} />
                ))}
            </div>
        </>
    )
}