import { CategoryIdBsIconOption } from '@symplicity/central-types';
import { useCallback } from 'react';
import { LocalCategoryMapV2 } from 'src/models/central/central-mapping.model';
import { useRootSelector } from 'src/ui/shared/hooks/root-selector.hook';

const getNames = (options: CategoryIdBsIconOption[], ids: number[]) =>
    options.filter(i => ids.includes(i.value)).map(i => i.name);

export const useCentralCategoryNames = () => {
    const localV2 = useRootSelector(state => state.central.configuration.clientCategoryMap);
    const { industries, occupations, employmentTypes } = useRootSelector(
        state => state.central.configuration.value!
    );

    const getCentralCategoryNames = useCallback(
        (key: keyof LocalCategoryMapV2, id: number) => {
            const centralIds = (localV2[key][id] || []).map(i => Number(i));

            switch (key) {
                case 'industries':
                    return getNames(industries || [], centralIds);
                case 'occupations':
                    return getNames(occupations || [], centralIds);
                case 'typesOfWork':
                    return getNames(employmentTypes || [], centralIds);
            }
        },
        [employmentTypes, industries, localV2, occupations]
    );

    return { getCentralCategoryNames };
};
