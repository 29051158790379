import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExperienceSkill } from "src/typings/api-models.generated";

interface Props {
    experienceSkill: ExperienceSkill;
}

export const ExperienceSkillRankDisplay = ({ experienceSkill: { rank } }: Props) => {
    let number = 0;
    switch (rank) {
        case 'fiveStar': {
            number = 5;
            break;
        }
        case 'fourStar': {
            number = 4;
            break;
        }
        case 'threeStar': {
            number = 3;
            break;
        }
        case 'twoStar': {
            number = 2;
            break;
        }
        case 'oneStar': {
            number = 1;
            break;
        }
    }

    if (number === 0) {
        return <small>({rank})</small>
    }

    return (
        <span className="text-nowrap">
            {Array.from({ length: 5 }).map((_, index) => {
                return (
                    <FontAwesomeIcon
                        key={index}
                        icon={faStar}
                        color={index < number ? 'gold' : 'grey'}
                    />
                );
            })}
        </span>
    );
}