import { useMemo } from 'react';
import { VerificationNormalized } from 'src/models/verifications/verification.models';
import { Details } from 'src/ui/shared/components/details/Details';
import { Html } from 'src/ui/shared/components/html/Html';
import { VerificationEndorsementDisplay } from '../display/VerificationEndorsementDisplay';
import { VerificationExperienceReflectionDisplay } from '../display/VerificationExperienceReflectionDisplay';
import { VerificationResourceListDisplay } from '../display/VerificationResourceListDisplay';
import { VerificationSkillListDisplay } from '../display/VerificationSkillListDisplay';
import { VerificationSkillReflectionDisplay } from '../display/VerificationSkillReflectionDisplay';
import { VerificationDetailsHeader } from './VerificationDetailsHeader';
import { VerificationDetailsSectionTitle } from './VerificationDetailsSectionTitle';
import { VerificationProperties } from './VerificationProperties';

interface Props {
    normalized: VerificationNormalized;
}

export const VerificationDetails = ({ normalized }: Props): React.ReactElement => {

    const hasReflections = useMemo(() => {
        return normalized.experienceReflections || normalized.skills.some(x => x.reflections);
    }, [normalized.experienceReflections, normalized.skills]);

    return (
        <Details
            header={<VerificationDetailsHeader normalized={normalized} />}
            properties={<VerificationProperties normalized={normalized} />}
        >
            <VerificationDetailsSectionTitle>Description</VerificationDetailsSectionTitle>
            <Html className="mb-4" source={normalized.experience.description || ''} />

            <VerificationSkillListDisplay skills={normalized.skills} />
            <VerificationResourceListDisplay resources={normalized.resources} />
            <VerificationEndorsementDisplay experience={normalized.experience} />

            {hasReflections && (<hr />)}

            <VerificationExperienceReflectionDisplay normalized={normalized} />

            {normalized.skills.map(x => (
                <VerificationSkillReflectionDisplay key={x.skill.id} normalized={x} />
            ))}
        </Details>
    );
};
